import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import { validators } from '../../export/validations/validations';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect';
import useCodelists from '../../../../../hooks/useCodelists';
import HideComponent from '../../../../../utils/HideComponent';

type Fields = 'payment.taxAssessedAmount' | 'payment.taxAssessedAmountCurrencyId';

export const calculationOfTaxesTotalBlockValidation = {
    childValidators: {
        'payment.taxAssessedAmount': [validators.required(), validators.number(), validators.float(16, 2)],
        'payment.taxAssessedAmountCurrencyId': [validators.required(), validators.exact(3)],
    },
};

interface Props extends BlockProps<Fields> {}

const CalculationOfTaxesTotalBlock = ({ path = 'commodity.dutyTaxFee', fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/7', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="payment.taxAssessedAmount">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('calculationOfTaxeTotal')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="payment.taxAssessedAmountCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('calculationOfTaxesTotalCurrency')}
                        {...getFormikProps(field.name, form)}
                        condensed
                        selectOptions={cdsCodelists?.exchangeRateCurrencies}
                        codeValidation={[validators.exact(3)]}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CalculationOfTaxesTotalBlock;
