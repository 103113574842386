import { cloneDeep, curry } from 'lodash';
import { IrelandImportDeclaration, GoodsShipmentItem } from 'store/declarations/ireland/import-declaration';
import { h1Box44KeyNames, h1PathBox44, h1PathBox44Products } from 'views/declarations/common/box44/box-44-utils';
import IrelandImportDeclarationUtils, { makeTransformation } from '../utils';

const IrelandImportH1DeclarationUtils = {
    transformForClient: (declaration: IrelandImportDeclaration | undefined) => {
        if (!declaration) return {};
        return makeTransformation(
            declaration,
            curry(IrelandImportDeclarationUtils.box44Transform)(
                IrelandImportDeclarationUtils.box44ToObject,
                h1PathBox44,
                h1Box44KeyNames
            )
        );
    },
    transformForServer: (declaration: IrelandImportDeclaration | undefined) => {
        if (!declaration) return {};
        let newDeclaration = cloneDeep(declaration);

        newDeclaration = IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToArray,
            h1PathBox44,
            h1Box44KeyNames,
            newDeclaration
        );

        IrelandImportDeclarationUtils.additionsAndDeductions(newDeclaration);

        return newDeclaration;
    },
    transformProductForClient: (product: GoodsShipmentItem | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToObject,
            h1PathBox44Products,
            h1Box44KeyNames,
            cloneDeep(product)
        );
    },
    transformProductForServer: (product: GoodsShipmentItem | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToArray,
            h1PathBox44Products,
            h1Box44KeyNames,
            cloneDeep(product)
        );
    },
};

export default IrelandImportH1DeclarationUtils;
