export class FieldMapper {
    private static readonly mapper: { [key: string]: string } = {
        'consignment.locationOfGoods.gNSS': 'consignment.locationOfGoods.gnss.latitude',
        'consignment.locationOfGoods.economicOperator':
            'consignment.locationOfGoods.economicOperator.identificationNumber',
        transportCharges: 'transportCharges.methodOfPayment',
        'consignment.houseConsignment.0.transportCharges':
            'consignment.houseConsignment.0.transportCharges.methodOfPayment',
        'consignment.locationOfGoods.customsOffice': 'consignment.locationOfGoods.customsOffice.referenceNumber',
    };

    public static getFieldName(key: string): string | undefined {
        return this.mapper[key];
    }
}
