import { Select as AntSelect, SelectProps as AntSelectProps } from 'antd';
import { TestProps } from 'core/utils/testTypes';
import { FC } from 'react';

export interface SelectProps extends AntSelectProps<string | number>, TestProps {
    value?: string;
    showArrow?: boolean;
    showSearch?: boolean;
    filterOption?: any;
    elementLocator?: string;
}

export const Option = AntSelect.Option;
export const Select: FC<SelectProps> = (props: SelectProps) => (
    <AntSelect {...props} data-testid={props.testId} data-elementLocator={props.elementLocator}>
        {props.children}
    </AntSelect>
);
