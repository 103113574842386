export class CardMapper {
    private static readonly mapper: { [key: string]: string } = {
        'consignment.locationOfGoods': 'location-of-goods-form-card',
        'consignment.placeOfLoading': 'place-of-loading-form-card',
        'consignment.placeOfUnloading': 'place-of-unloading-form-card',
    };

    public static getCardId(key: string): string | undefined {
        return this.mapper[key];
    }
}
