import { Spin, Tabs } from 'antd';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useBreadcrumb from '../../../../hooks/useBreadcrumb';
import useDeclarations from '../../../../hooks/useDeclarations';
import useFormUtils from '../../../../hooks/useFormUtils';
import useGetDeclarationMapValues from '../../../../hooks/useGetDeclarationMapValues';
import { DeclarationStatus } from '../../../../store/declarations/enums/common/declaration-status';
import { getDeclarationTitle } from '../../../custom-declaration/declaration-table/DeclarationColumns';
import { StyledHeader, StyledLayout, StyledTabs } from '../../Form.styles';
import { barcodeDisabled } from '../../uk/gvms/components/barcode/BarcodeView';
import { externalEntityMap } from '../../utils/declaration-utils';
import DeclarationTabKeys from '../../utils/DeclarationTabKeys';
import { declarationHasBeenSubmitted } from '../../utils/form-utils';
import useHandleActiveTabKeyBasedOnLocation, {
    getDeclarationActiveTabKey,
} from './utils/useHandleActiveTabKeyBasedOnLocation';
import useRefetchDeclarationOnTabChangeIfAmendable from './utils/useRefetchDeclarationOnTabChangeIfAmendable';

const { TabPane } = Tabs;

interface Props {
    children: ReactNode;
}

/**
 *
 * DeclarationTabView Component
 *
 * This component navigates to and controls the different tabs of the declaration.
 *
 * Composition techniques:
 * - Moving state down - isolate state changes - "activeTabKey".
 * - Components as children prop - props not affected by local state changes.
 *
 */
const DeclarationTabView = ({ children }: Props) => {
    const { declarationId } = useParams<{ declarationId: string }>();
    const { setBreadcrumbRoutes } = useBreadcrumb();
    const { isAis } = useFormUtils();
    const location = useLocation();
    const navigate = useNavigate();
    const { getDeclaration, declaration } = useDeclarations();
    const { declarationName } = useGetDeclarationMapValues();

    const [declarationIsFetching, setDeclarationIsFetching] = useState<boolean>(false);
    const [activeTabKey, setActiveTabKey] = useState<DeclarationTabKeys>(getDeclarationActiveTabKey(location));

    const fetchDeclaration = async (declarationId: string) => {
        setDeclarationIsFetching(true);
        try {
            await getDeclaration(declarationId);
        } finally {
            setDeclarationIsFetching(false);
        }
    };

    useHandleActiveTabKeyBasedOnLocation({ setActiveTabKey });
    useRefetchDeclarationOnTabChangeIfAmendable({
        activeTabKey,
    });
    useEffect(() => {
        if (declarationId) {
            fetchDeclaration(declarationId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [declarationId]);

    useEffect(() => {
        const secondBreadcrumbName = !declaration?.declarationExternalEntity
            ? ''
            : externalEntityMap[declaration?.declarationExternalEntity];

        setBreadcrumbRoutes([
            { breadcrumbName: 'Customs Declarations', path: '' },
            {
                breadcrumbName: `${secondBreadcrumbName} Customs`,
                path: '',
            },
            {
                breadcrumbName: `${!declarationIsFetching ? getDeclarationTitle(declaration) : ''}`,
                path: '',
            },
        ]);
    }, [declaration, setBreadcrumbRoutes, declarationIsFetching]);

    const formTabName = useMemo(() => {
        if (
            declaration?.status === DeclarationStatus.RELEASED ||
            declaration?.status === DeclarationStatus.GOODS_RELEASED_FOR_EXPORT
        )
            return 'Summary';
        return `${declarationName} Form`;
    }, [declaration?.status, declarationName]);

    const handleDeclarationFormTabs = useMemo(() => {
        if (declaration?.status && declarationHasBeenSubmitted(declaration.status)) {
            return <TabPane tab="Form Information" key={DeclarationTabKeys.VIEW_ONLY} />;
        } else if (
            declaration?.status === DeclarationStatus.RELEASED ||
            declaration?.status === DeclarationStatus.GOODS_RELEASED_FOR_EXPORT
        ) {
            return (
                <>
                    <TabPane tab={formTabName} key={DeclarationTabKeys.SUMMARY} />
                    <TabPane tab="Form Information" key={DeclarationTabKeys.VIEW_ONLY} />
                </>
            );
        } else {
            return <TabPane tab={formTabName} key={DeclarationTabKeys.FORM} />;
        }
    }, [declaration?.status, formTabName]);

    if (declarationIsFetching || getDeclarationActiveTabKey(location) !== activeTabKey)
        return (
            <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <Spin size="large" tip="Loading declaration..." />
            </div>
        );

    return (
        <StyledLayout>
            <StyledHeader>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <StyledTabs
                        onChange={(k) => {
                            const tab = k as DeclarationTabKeys;
                            if (tab === DeclarationTabKeys.FORM) {
                                navigate(`/declarations/${declaration?.id}`, {
                                    state: { activeTabKey: tab },
                                });
                            } else {
                                navigate(`/declarations/${declaration?.id}/${tab}`, {
                                    state: { activeTabKey: tab },
                                });
                            }
                            setActiveTabKey(tab);
                        }}
                        activeKey={activeTabKey}
                    >
                        {handleDeclarationFormTabs}
                        {declaration?.gvmsDeclaration || declaration?.preBoardingNotification ? (
                            <TabPane
                                tab="Barcode"
                                key={DeclarationTabKeys.BARCODE}
                                disabled={barcodeDisabled(declaration)}
                            />
                        ) : (
                            <>
                                <TabPane tab="Documentation" key={DeclarationTabKeys.DOCUMENTATION} />
                                <TabPane tab="Customer Details" key={DeclarationTabKeys.CUSTOMER_DETAILS} />
                            </>
                        )}
                        {!declaration?.preBoardingNotification && (
                            <TabPane tab="History" key={DeclarationTabKeys.HISTORY} />
                        )}
                        {isAis && declaration?.status !== 'DRAFT' && (
                            <TabPane tab="Refunds" key={DeclarationTabKeys.REFUNDS} />
                        )}
                    </StyledTabs>
                </div>
            </StyledHeader>
            {children}
        </StyledLayout>
    );
};

export default DeclarationTabView;
