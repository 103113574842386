import Table from 'components/ui/base/table/Table';
import { ListPayload } from 'core/http/response';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Declaration } from 'store/declarations/declaration';
import { DeclarationStatus } from 'store/declarations/enums/common/declaration-status';
import { declarationHasBeenSubmitted } from '../../declarations/utils/form-utils';
import dashboardColumns from './DeclarationColumns';
import { TablePaginationConfig } from 'antd';
import './DeclarationsTable.css';
import { ColumnsType, ColumnGroupType, ColumnType } from 'antd/lib/table';
import { DeclarationInternalType } from '../../../store/declarations/enums/common/declaration-internal-type';
import gvmsColumns from './GvmsColumns';
import { GvmsReferenceData } from '../../../store/declarations/uk/gvms-declaration';
import { GetComponentProps } from 'rc-table/lib/interface';
import moment from 'moment';

const getDeclarationTableRowClassName = (record: any) => {
    const declarationStatus = record?.status ?? record?.declaration?.status;
    const documentsUploadRequested =
        record?.irelandImportDeclaration?.documentsUploadRequested ??
        record?.irelandH7ImportDeclaration?.documentsUploadRequested ??
        record?.cdsDeclaration?.documentsUploadRequested ??
        record?.actionRequired ??
        record?.inspectionRequired;

    const gmrExpiryDays =
        moment(record?.gvmsDeclaration?.gmrExpiredAt).startOf('day').diff(moment().startOf('day'), 'days') + 1;

    let className = 'declaration-table-row';
    if (
        declarationStatus === DeclarationStatus.REJECTED ||
        declarationStatus === DeclarationStatus.INVALID ||
        gmrExpiryDays < 0
    ) {
        className += ' declaration-table-row-red';
    } else if (documentsUploadRequested) {
        className += ' declaration-table-row-orange';
    } else if (record?.gvmsDeclaration?.gmrExpiredAt && gmrExpiryDays <= 7 && gmrExpiryDays >= 0) {
        className += ' declaration-table-row-yellow';
    }

    return className;
};

interface Props {
    data: ListPayload<Declaration> | null;
    handleDuplicate?: (declaration: Declaration) => void;
    onMirror?: (declaration: Declaration) => void;
    onUnarchive?: (ids: string[]) => void;
    onArchive?: (ids: string[]) => void;
    loading?: boolean;
    onPrint?: (declarationId: string) => void;
    onDelete?: (declarationId: string) => void;
    onChange?: (pagination: TablePaginationConfig) => void;
    withPagination?: boolean;
    filterDataColumns?: (dataColumns: ColumnsType<any>) => (ColumnGroupType<any> | ColumnType<any>)[];
    gvmsReferenceData?: GvmsReferenceData;
    onBarcode?: (declaration: Declaration | undefined) => void;
    onRow?: GetComponentProps<any> | undefined;
    onCoreTemplateToggle?: (declaration: Declaration) => void;
    pagination?: TablePaginationConfig;
}

const DeclarationTable: FC<Props> = ({
    data,
    handleDuplicate,
    onMirror: handleMirror,
    onArchive,
    onUnarchive,
    loading,
    onPrint,
    onDelete,
    onChange,
    withPagination = true,
    filterDataColumns,
    gvmsReferenceData,
    onBarcode,
    onRow,
    onCoreTemplateToggle,
    pagination,
}) => {
    const navigate = useNavigate();
    const { type, dashboardView } = useParams<{
        type: DeclarationInternalType;
        dashboardView: 'archived' | 'core-declarations';
    }>();
    const atArchivedView = dashboardView === 'archived';
    const atCoreDeclView = dashboardView === 'core-declarations';

    const handleColumnActions = () => {
        if (type === DeclarationInternalType.GVMS) {
            if (atArchivedView) {
                return gvmsColumns(gvmsReferenceData, { onUnarchive, onBarcode });
            } else if (atCoreDeclView) {
                return gvmsColumns(gvmsReferenceData, { onBarcode, onCoreTemplateToggle });
            }

            return gvmsColumns(gvmsReferenceData, {
                onDuplicate: handleDuplicate,
                onArchive,
                onBarcode,
                onDelete,
                onCoreTemplateToggle,
            });
        } else if (type === DeclarationInternalType.PBN) {
            if (atArchivedView) {
                return dashboardColumns({ onUnarchive, onBarcode });
            } else if (atCoreDeclView) {
                return dashboardColumns({ onBarcode, onCoreTemplateToggle });
            }

            return dashboardColumns({
                onArchive,
                onBarcode,
                onCoreTemplateToggle,
            });
        } else if (atArchivedView) {
            return dashboardColumns({ onUnarchive });
        } else if (atCoreDeclView) {
            return dashboardColumns({ onCoreTemplateToggle });
        }

        return dashboardColumns({
            onDuplicate: handleDuplicate,
            onArchive,
            onPrint,
            onMirror: handleMirror,
            onDelete,
            filterDataColumns,
            onCoreTemplateToggle,
        });
    };

    return (
        <>
            <Table
                rowKey="id"
                columns={handleColumnActions()}
                dataSource={data?.list}
                rowClassName={getDeclarationTableRowClassName}
                onRow={
                    onRow ||
                    ((record: Declaration) => ({
                        'data-testid': `declaration-table-row`,
                        onClick: (event) => {
                            event.stopPropagation();
                            if (declarationHasBeenSubmitted(record.status) || record.archived) {
                                navigate(`/declarations/${record.id}/view-only`);
                            } else if (
                                record.status === DeclarationStatus.RELEASED ||
                                record.status === DeclarationStatus.GOODS_RELEASED_FOR_EXPORT
                            ) {
                                navigate(`/declarations/${record.id}/summary`);
                            } else {
                                navigate(`/declarations/${record.id}`);
                            }
                        },
                    }))
                }
                pagination={
                    pagination ||
                    (withPagination
                        ? {
                              current: (data?.pageNumber || 0) + 1,
                              total: data?.total,
                              showSizeChanger: false,
                              pageSize: data?.pageSize,
                              position: ['bottomCenter'],
                          }
                        : false)
                }
                onChange={onChange}
                loading={loading}
            />
        </>
    );
};

export default DeclarationTable;
