import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import DeclarationField from '../../../common/DeclarationField';
import FormSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import FormInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import useCodelists from '../../../../../hooks/useCodelists';
import { validators } from '../../export/validations/validations';
import HideComponent from '../../../../../utils/HideComponent';

type Fields = 'specificTaxBaseQuantity' | 'specificTaxBaseQuantityCurrencyId';

export const calculationOfTaxesTaxBaseBlockValidation = {
    childValidators: {
        specificTaxBaseQuantity: [validators.required(), validators.number(), validators.float(16, 6)],
        specificTaxBaseQuantityCurrencyId: [validators.required(), validators.maxLength(6)],
    },
};

interface Props extends BlockProps<Fields> {}

const CalculationOfTaxesTaxBaseBlock = ({ path = 'commodity.dutyTaxFee', fieldOptions, hide }: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '4/4', []);

    return (
        <HideComponent hide={hide}>
            <DeclarationField pathPrefix={path} name="specificTaxBaseQuantity">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        required
                        refNumber={ucc}
                        label={t('calculationOfTaxesTaxBaseQuantity')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>

            <DeclarationField pathPrefix={path} name="specificTaxBaseQuantityCurrencyId">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        required
                        refNumber={ucc}
                        label={t('calculationOfTaxesTaxBaseUnitAndQualifier')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.measureUnitTypes}
                        condensed
                        codeValidation={[validators.maxLength(6)]}
                    />
                )}
            </DeclarationField>
        </HideComponent>
    );
};

export default CalculationOfTaxesTaxBaseBlock;
