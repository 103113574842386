import {
    CopyOutlined,
    ContainerOutlined,
    PrinterOutlined,
    DeleteOutlined,
    LinkOutlined,
    BarcodeOutlined,
    StarOutlined,
    StarFilled,
} from '@ant-design/icons';
import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { DeclarationStatus } from '../../../store/declarations/enums/common/declaration-status';
import { Declaration } from '../../../store/declarations/declaration';
import { CommandButton } from '../../../components/ui/composed/dashboard/DashboardTable';

export const CoreTemplateButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (record: any) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );

    return (
        <CommandButton
            data-testid="declaration-core-template-button-row"
            tooltip={!record?.isCoreTemplate ? 'Use as Core Template' : 'Remove as Core Template'}
            icon={!record?.isCoreTemplate ? <StarFilled /> : <StarOutlined />}
            onClick={handleClick}
        />
    );
};

export const BarcodeButton = ({
    disabled,
    onClick,
    record,
}: {
    disabled: boolean;
    onClick?: (record: Declaration | undefined) => void;
    record?: Declaration | undefined;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );
    return (
        <CommandButton
            data-testid="declaration-duplicate-button-row"
            tooltip="Barcode"
            icon={<BarcodeOutlined />}
            onClick={handleClick}
            disabled={disabled}
        />
    );
};

export const DeleteButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (recordId: string) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record.id);
        },
        [onClick, record.id]
    );

    const disabled = record.status !== DeclarationStatus.DRAFT;
    return (
        <CommandButton
            data-testid="declaration-delete-button-row"
            tooltip={disabled ? '' : 'Delete'}
            icon={<DeleteOutlined />}
            onClick={handleClick}
            disabled={disabled}
        />
    );
};

export const PrintButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (recordId: string) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record.id);
        },
        [onClick, record.id]
    );

    const disabled =
        record.status === DeclarationStatus.DRAFT ||
        !(record?.irelandImportDeclaration || record?.irelandH7ImportDeclaration || record?.ieExportDeclaration);
    return (
        <CommandButton
            data-testid="declaration-print-button-row"
            tooltip={disabled ? '' : 'Documents'}
            icon={<PrinterOutlined />}
            onClick={handleClick}
            disabled={disabled}
        />
    );
};

export const DuplicateButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (record: any) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );
    return (
        <CommandButton
            data-testid="declaration-duplicate-button-row"
            tooltip="Duplicate"
            icon={<CopyOutlined />}
            onClick={handleClick}
        />
    );
};

export const ArchiveButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (recordIds: string[]) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.([record.id]);
        },
        [onClick, record.id]
    );
    return (
        <CommandButton
            data-testid="declaration-archive-button-row"
            tooltip="Archive"
            icon={<ContainerOutlined />}
            onClick={handleClick}
        />
    );
};

export const UnarchiveButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (recordIds: string[]) => void;
}): ReactElement => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.([record.id]);
        },
        [onClick, record.id]
    );
    return (
        <CommandButton
            data-testid="declaration-unarchive-button-row"
            tooltip="Unarchive"
            icon={<ContainerOutlined />}
            onClick={handleClick}
        />
    );
};

export const MirrorButton = ({
    record,
    onClick,
}: {
    record: any;
    onClick?: (record: any) => void;
}): ReactElement | null => {
    const handleClick = useCallback<MouseEventHandler<HTMLElement>>(
        (e) => {
            e.stopPropagation();
            onClick?.(record);
        },
        [onClick, record]
    );

    const disabled = record.status !== DeclarationStatus.RELEASED && record.status !== DeclarationStatus.RISKED;

    // TODO Mirroring - Show when necessary
    const hidden = record.cdsDeclaration?.messageType !== 'B1';

    if (hidden) return null;

    return (
        <CommandButton
            data-testid="declaration-mirror-button-row"
            tooltip={disabled ? '' : 'Mirror'}
            icon={<LinkOutlined />}
            onClick={handleClick}
            disabled={disabled}
        />
    );
};
