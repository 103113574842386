import { Col, DrawerProps, Table, notification } from 'antd';
import { useFormik, useFormikContext } from 'formik';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';
import Drawer from '../../../../../components/ui/base/drawer/Drawer';
import StyledButton from '../../../../../views/declarations/common/search-customer/SearchCustomerButton';
import { PlusOutlined, SaveOutlined } from '@ant-design/icons';
import Searchbar from '../../../base/searchbar';
import {
    StyledCloseOutlined,
    StyledDeleteOutlined,
    StyledSaveOutlined,
    StyledEditOutlined,
} from '../../../../../views/declarations/sections/customer-documents/CustomerDocuments.styles';
import { getFormikProps } from '../../../../../views/declarations/utils/form-utils';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import useFormUtils from '../../../../../hooks/useFormUtils';
import DeleteModal from 'components/ui/base/modal/Modal';
import { H5 } from '../../../base/typography';
import useCodelists from '../../../../../hooks/useCodelists';
import codelistMenuDrawerMapHelper, { ListFunctions, transformCodelistForTable } from './codelistMenuDrawerMap';
import useGlobalOverlay from '../../../../../hooks/useGlobalOverlay';
import Fuse from 'fuse.js';
import { useTemplateContext } from '../../template/TemplateContext';
import addPathPrefix from '../../../../../utils/addPathPrefix';
import { flushSync } from 'react-dom';
import validate, {
    FormModel,
    Validator,
    transformErrorsForFormik,
    validators,
} from 'views/declarations/uk/export/validations/validations';
interface Props extends DrawerProps {
    codelistData?: any;
    fieldLabel?: string;
    fieldName?: string;
    onClose?: () => void;
    codeValidation: Validator[];
}

const CodelistMenuDrawer = ({
    codelistData,
    fieldLabel,
    fieldName,
    visible,
    onClose,
    codeValidation,
}: Props): ReactElement => {
    const { ...requests } = useCodelists();
    const { country } = useFormUtils();
    const { showGlobalOverlay, hideGlobalOverlay } = useGlobalOverlay();
    const { getFieldHelpers, getFieldProps } = useFormikContext();
    const { template, templateFormik, form } = useTemplateContext();

    const formik = useFormik({
        initialValues: {
            code: '',
            description: '',
            city: '',
            region: '',
        },
        validate: async (values) => {
            return transformErrorsForFormik(
                await validate(new FormModel(values), {
                    childValidators: {
                        code: [validators.required(), ...codeValidation],
                        description: [validators.required()],
                    },
                })
            );
        },
        onSubmit: () => {},
    });

    const [isCreateBtnClicked, setIsCreateBtnClicked] = useState<boolean | undefined>(undefined);
    const [deleteData, setDeleteData] = useState<{ recordId: number | undefined; deleteModalVisible: boolean }>({
        recordId: undefined,
        deleteModalVisible: false,
    });
    const [editData, setEditData] = useState<{ recordId: number | undefined; tableRowId: number | undefined }>({
        recordId: undefined,
        tableRowId: undefined,
    });
    const [filteredTransformedCodelistForTable, setFilteredTransformedCodelistForTable] = useState<any>(undefined);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

    const formField = useMemo(() => {
        const path = template && templateFormik ? addPathPrefix(`${form}.defaults`, fieldName) : fieldName;
        if (!path) return null;

        const formikProps =
            template && templateFormik
                ? { getFieldProps: templateFormik?.getFieldProps, getFieldHelpers: templateFormik?.getFieldHelpers }
                : { getFieldProps, getFieldHelpers };
        if (!formikProps) return null;

        return getFormikProps(path, {
            ...formikProps,
        });
    }, [getFieldHelpers, getFieldProps, template, fieldName, templateFormik, form]);

    const transformedCodelistForTable = useMemo(() => transformCodelistForTable(codelistData), [codelistData]);
    const mapper = useMemo(() => codelistMenuDrawerMapHelper[country], [country]);

    const addOrUpdateRequest = useMemo(
        () => (editData.recordId ? mapper.updateCodelist : mapper.createCodelist),
        [editData.recordId, mapper]
    );
    const addOrUpdateParams = useMemo(() => {
        const params = {
            type: codelistData?.[0]['type'],
            name: mapper?.getCodelistName(codelistData),
            code: formik.values.code,
            description: formik.values.description,
            data: `${JSON.stringify({ city: formik.values.city, region: formik.values.region })}`,
        };

        return !editData?.recordId ? params : { ...params, id: editData.recordId };
    }, [editData.recordId, codelistData, formik.values, mapper]);

    const tableDataSource = useMemo(
        () => (!isCreateBtnClicked ? filteredTransformedCodelistForTable : [{}].concat(transformedCodelistForTable)),
        [isCreateBtnClicked, filteredTransformedCodelistForTable, transformedCodelistForTable]
    );

    useEffect(() => {
        if (!transformedCodelistForTable) return;
        setFilteredTransformedCodelistForTable(transformedCodelistForTable);
    }, [transformedCodelistForTable]);

    const handleDeleteButtonClick = useCallback((e: any, recordId: number) => {
        e.stopPropagation();
        setDeleteData({
            recordId,
            deleteModalVisible: true,
        });
    }, []);

    const handleEditButtonClick = useCallback(
        (e: any, recordId: number, tableRowId: number) => {
            e.stopPropagation();
            setEditData({ recordId, tableRowId });

            const rowForEdit = transformedCodelistForTable?.find((codelist: any) => codelist.id === recordId);

            rowForEdit?.code && formik.setFieldValue('code', rowForEdit.code);
            rowForEdit?.description && formik.setFieldValue('description', rowForEdit.description);
            rowForEdit?.city && formik.setFieldValue('city', rowForEdit.city);
            rowForEdit?.region && formik.setFieldValue('region', rowForEdit.region);
        },
        [formik, transformedCodelistForTable]
    );

    const handlePopulateFormField = useCallback(() => {
        const selectedRow = filteredTransformedCodelistForTable?.find(
            (codelist: any) => codelist.key === selectedRowKeys?.[0]
        );
        if (!selectedRow?.code) return setSelectedRowKeys([]);

        const formFieldHelpers = formField?.fieldHelper;
        flushSync(() => formFieldHelpers?.setValue(selectedRow?.code));
        formFieldHelpers?.setTouched(true);
        onClose?.();
    }, [filteredTransformedCodelistForTable, formField?.fieldHelper, onClose, selectedRowKeys]);

    const resetFormState = useCallback(
        () =>
            !editData.recordId
                ? setIsCreateBtnClicked(false)
                : setEditData({ recordId: undefined, tableRowId: undefined }),
        [editData?.recordId]
    );

    const handleSubmit = useCallback(async () => {
        showGlobalOverlay({
            type: 'LoadingOverlay',
            payload: { message: 'Saving custom record...' },
        });

        const validation = await formik.validateForm();
        formik.setTouched(validation as any);
        if (!isEmpty(validation)) return hideGlobalOverlay();

        addOrUpdateRequest(addOrUpdateParams)
            .then((response: any) => requests[mapper?.listCodelists as ListFunctions](response.data.type))
            .catch(() =>
                notification.error({
                    message: 'Please try again later.',
                })
            )
            .finally(() => {
                resetFormState();
                hideGlobalOverlay();
                formik.resetForm();
            });
    }, [
        formik,
        mapper,
        requests,
        addOrUpdateRequest,
        addOrUpdateParams,
        showGlobalOverlay,
        hideGlobalOverlay,
        resetFormState,
    ]);

    const handleDelete = useCallback(async () => {
        if (!deleteData.recordId) return;

        const deletedRow = transformedCodelistForTable?.find((codelist: any) => codelist.id === deleteData.recordId);
        if (deletedRow.code === formField?.fieldProps.value) formField?.fieldHelper.setValue(null);

        mapper
            ?.deleteCodelist(deleteData.recordId)
            .then(requests[mapper?.listCodelists as ListFunctions])
            .finally(() => {
                setDeleteData({ recordId: undefined, deleteModalVisible: false });
            });
    }, [deleteData, mapper, requests, transformedCodelistForTable, formField]);

    const handleSearch = useCallback(
        (input: string) => {
            if (!input) return setFilteredTransformedCodelistForTable(transformedCodelistForTable);

            resetFormState();
            const fuse = new Fuse(transformedCodelistForTable, { keys: ['code', 'description', 'city', 'region'] });
            const searchResult = fuse.search(input);
            const filterResult = searchResult.map((rowData: any) => rowData.item);

            setFilteredTransformedCodelistForTable(filterResult);
        },
        [transformedCodelistForTable, resetFormState]
    );

    const handleRowSelection = useCallback(
        (keys: React.Key[]) => {
            if (keys.length !== 1) return;

            const deselectRow = selectedRowKeys.some((selectedKey: React.Key) => selectedKey === keys[0]);
            if (deselectRow) return setSelectedRowKeys([]);

            return setSelectedRowKeys(keys);
        },
        [selectedRowKeys]
    );

    const columns = useMemo(() => {
        const _columns = [
            {
                title: 'Code',
                dataIndex: 'code',
                key: 'code',
                render: (text: string, record: any, index: number) =>
                    (isCreateBtnClicked && index === 0) || editData.tableRowId === index ? (
                        <span onClick={(e) => e.stopPropagation()}>
                            <FormInput {...getFormikProps('code', formik)} condensed normal />
                        </span>
                    ) : (
                        text
                    ),
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
                render: (text: string, record: any, index: number) =>
                    (isCreateBtnClicked && index === 0) || editData.tableRowId === index ? (
                        <span onClick={(e) => e.stopPropagation()}>
                            <FormInput {...getFormikProps('description', formik)} condensed normal />
                        </span>
                    ) : (
                        text
                    ),
            },
            {
                title: 'Created By',
                dataIndex: 'createdBy',
                key: 'createdBy',
                render: (text: string, record: any) => <span>{record.creatorName}</span>,
            },
            {
                title: 'Actions',
                dataIndex: 'actions',
                key: 'actions',
                render: (text: string, record: any, index: any) =>
                    (isCreateBtnClicked && index === 0) || editData.tableRowId === index ? (
                        <div style={{ display: 'flex' }}>
                            <span>
                                <StyledSaveOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleSubmit();
                                    }}
                                />
                            </span>
                            <span>
                                <StyledCloseOutlined
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        formik.resetForm();
                                        resetFormState();
                                    }}
                                />
                            </span>
                        </div>
                    ) : (
                        !!record?.creatorName && (
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <StyledEditOutlined onClick={(e) => handleEditButtonClick(e, record?.id, index)} />
                                </div>
                                <span>
                                    <StyledDeleteOutlined onClick={(e) => handleDeleteButtonClick(e, record?.id)} />
                                </span>
                            </div>
                        )
                    ),
            },
        ];

        const exitCustomsOfficeColumns = [
            {
                title: 'City',
                dataIndex: 'city',
                key: 'city',
                render: (text: string, record: any, index: number) =>
                    (isCreateBtnClicked && index === 0) || editData.tableRowId === index ? (
                        <span onClick={(e) => e.stopPropagation()}>
                            <FormInput {...getFormikProps('city', formik)} condensed normal />
                        </span>
                    ) : (
                        text
                    ),
            },
            {
                title: 'Region',
                dataIndex: 'region',
                key: 'region',
                render: (text: string, record: any, index: number) =>
                    (isCreateBtnClicked && index === 0) || editData.tableRowId === index ? (
                        <span onClick={(e) => e.stopPropagation()}>
                            <FormInput {...getFormikProps('region', formik)} condensed normal />
                        </span>
                    ) : (
                        text
                    ),
            },
        ] as const;

        if (codelistData?.[0]['name'] === 'exitCustomsOffices') {
            _columns.splice(2, 0, ...exitCustomsOfficeColumns);
        }

        return _columns;
    }, [
        codelistData,
        editData.tableRowId,
        formik,
        handleDeleteButtonClick,
        handleEditButtonClick,
        handleSubmit,
        isCreateBtnClicked,
        resetFormState,
    ]);

    return (
        <Drawer title={`${fieldLabel} Codelist Menu `} visible={visible} onClose={onClose} width={700}>
            <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                <StyledButton
                    condensed
                    disabled={!!!selectedRowKeys?.[0] || isCreateBtnClicked || !!editData.recordId}
                    title={'Populate Field'}
                    onClick={handlePopulateFormField}
                    style={{ margin: 0 }}
                    icon={<SaveOutlined />}
                />
                <StyledButton
                    disabled={!!editData.recordId}
                    condensed
                    title={'Create Custom Record'}
                    icon={<PlusOutlined />}
                    onClick={() => setIsCreateBtnClicked(true)}
                />
            </Col>
            <Col style={{ marginTop: 15 }}>
                <Searchbar
                    inputPlaceholder={
                        codelistData?.[0]['name'] === 'exitCustomsOffices'
                            ? 'Enter code, description, city or region'
                            : 'Enter code or description'
                    }
                    onChange={handleSearch}
                />
            </Col>
            <Col style={{ marginTop: 15 }}>
                <Table
                    dataSource={tableDataSource}
                    onChange={resetFormState}
                    rowSelection={{
                        selectedRowKeys,
                        onSelect: (row) => handleRowSelection([row.key]),
                    }}
                    onRow={(row) => ({
                        onClick: () => handleRowSelection([row.key]),
                    })}
                    columns={columns}
                />
            </Col>
            <DeleteModal
                title={<H5>Do you want to delete this custom record?</H5>}
                visible={deleteData.deleteModalVisible}
                onCancel={() => setDeleteData({ recordId: undefined, deleteModalVisible: false })}
                onOk={handleDelete}
                width={762}
                contentText={'If you remove this custom record, you will lose all the information associated with it.'}
            />
        </Drawer>
    );
};

export default CodelistMenuDrawer;
