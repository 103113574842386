import { curry } from 'lodash';
import { IrelandH7ImportDeclaration, GoodsShipmentItem } from 'store/declarations/ireland/h7-import-declaration';
import { h7Box44KeyNames, h7PathBox44, h7PathBox44Products } from 'views/declarations/common/box44/box-44-utils';
import IrelandImportDeclarationUtils, { makeTransformation } from '../utils';

const IrelandImportH7DeclarationUtils = {
    transformForClient: (declaration: IrelandH7ImportDeclaration | undefined) => {
        if (!declaration) return {};
        return makeTransformation(
            declaration,
            curry(IrelandImportDeclarationUtils.box44Transform)(
                IrelandImportDeclarationUtils.box44ToObject,
                h7PathBox44,
                h7Box44KeyNames
            )
        );
    },
    transformForServer: (declaration: IrelandH7ImportDeclaration | undefined) => {
        if (!declaration) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToArray,
            h7PathBox44,
            h7Box44KeyNames,
            declaration
        );
    },
    transformProductForClient: (product: GoodsShipmentItem | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToObject,
            h7PathBox44Products,
            h7Box44KeyNames,
            product
        );
    },
    transformProductForServer: (product: GoodsShipmentItem | undefined) => {
        if (!product) return {};
        return IrelandImportDeclarationUtils.box44Transform(
            IrelandImportDeclarationUtils.box44ToArray,
            h7PathBox44Products,
            h7Box44KeyNames,
            product
        );
    },
};

export default IrelandImportH7DeclarationUtils;
