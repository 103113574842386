import { FC, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useBusinessRules from '../../../../../../hooks/useBusinessRules';
import useDeclarations from '../../../../../../hooks/useDeclarations';
import useProducts from '../../../../../../hooks/useProducts';
import {
    AesInvalidError,
    AesInvalidNotification,
    AesRejectedError,
    AesRejectedNotification,
} from '../../../../../../store/declarations/ireland/export-declaration';
import { subtractOneFromEveryFoundNumber } from '../../../../../../utils/validationParserUtils';
import irelandExportValidationErrorsParser from '../../../../ireland/export/validation/IrelandExportValidationErrorsParser';
import { goToDeclarationField } from '../../../../utils/form-utils';
import ListErrorsRows from '../ListErrorsRows';
import { StyledHr, Title } from '../../DeclarationsNotificationsDrawer.styles';
import { ErrorRow } from '../ais/RevenueImportErrorDetails';
import useCodelists from '../../../../../../hooks/useCodelists';
import { dateStandardFormat, getHour } from 'core/utils/date';
import useGetDeclarationMapValues from 'hooks/useGetDeclarationMapValues';

const pathPrefixesToLowerCase = (path: string) =>
    path
        .split('.')
        .map((val) => val.charAt(0).toLowerCase() + val.substring(1))
        .join('.');

const RevenueExportErrorDetails: FC<{
    details?: AesRejectedError | AesInvalidError;
    onClose?: () => void;
    latestNotification: AesRejectedNotification | AesInvalidNotification;
    rulesAndConditions?: { text: string; url: string };
}> = ({ details, onClose, latestNotification, rulesAndConditions }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);
    const { products } = useProducts();
    const { declaration } = useDeclarations();
    const { aesCodelists } = useCodelists();
    const { aesRules, getIrelandBusinessRules } = useBusinessRules();
    const { itemPath } = useGetDeclarationMapValues();

    useEffect(() => {
        if (!aesRules) {
            getIrelandBusinessRules('AES');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fields: string[] = useMemo(
        () =>
            details?.errorPointer
                ? [pathPrefixesToLowerCase(subtractOneFromEveryFoundNumber(details?.errorPointer))]
                : [],
        [details?.errorPointer]
    );

    const doesFieldMatchPath = (field: string, itemPath: string | undefined): boolean => {
        if (!itemPath) return false;

        const fieldSplit = field.split('.');
        const itemSplit = itemPath.split('.');

        for (let i = 0; i < itemSplit.length; i++) {
            const fieldSegment = fieldSplit[i];
            const itemSegment = itemSplit[i];

            if (!itemSegment) continue;

            if (itemSegment === 'x') {
                if (!/^\d+$/.test(fieldSegment)) {
                    return false;
                }
            } else if (itemSegment !== fieldSegment) {
                return false;
            }
        }

        return true;
    };

    const stripProductPath = (field: string, itemPath: string): string => {
        let newPathSplit = [];
        const fieldSplit = field.split('.');
        const itemSplit = itemPath.split('.');

        let itemIndex = 0;
        let firstNumberSkipped = false; // Tracks if the first number in the remaining fieldPath has been skipped

        for (let fieldIndex = 0; fieldIndex < fieldSplit.length; fieldIndex++) {
            if (itemIndex < itemSplit.length) {
                // Check for exact match between fieldPath and itemPath
                if (fieldSplit[fieldIndex] === itemSplit[itemIndex]) {
                    itemIndex++;
                    continue;
                }
                // Check if 'x' in itemPath matches a number in fieldPath
                if (itemSplit[itemIndex] === 'x' && /^\d+$/.test(fieldSplit[fieldIndex])) {
                    itemIndex++;
                    continue;
                }
            }

            // If we've reached the end of itemPath
            if (itemIndex >= itemSplit.length) {
                // Skip the first number in the remaining fieldPath
                if (/^\d+$/.test(fieldSplit[fieldIndex]) && !firstNumberSkipped) {
                    firstNumberSkipped = true;
                    continue;
                }
                // Add remaining parts of fieldPath to the result
                newPathSplit.push(fieldSplit[fieldIndex]);
            }
        }

        return newPathSplit.join('.');
    };

    const handleOnClickField = async (field: string) => {
        if (field) {
            if (itemPath && doesFieldMatchPath(field, itemPath)) {
                // Get product
                const fieldParts = field.split('.');
                const itemPathParts = itemPath.split('.');
                const productIndex = itemPathParts.length;
                const productNumber = Number(fieldParts[productIndex]);
                const product = products?.list.at(productNumber);

                field = stripProductPath(field, itemPath);

                // Navigate to product
                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
            } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
                navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
            }

            onClose?.();
            goToDeclarationField(field, 100);
        }
    };

    const detailsContent = useMemo(() => {
        if (!details) return null;

        if ('errorReason' in details) {
            const businessRuleDescription = aesRules?.find(
                (businessRule) => businessRule.code === details?.errorReason
            )?.description;

            const message = aesCodelists?.functionalErrorCode.find(
                (codelist) => codelist.id === details?.errorCode
            )?.value;

            const businessRejectionType = aesCodelists?.rejectionCodeExit?.find(
                (codelist) =>
                    codelist.id ===
                    (latestNotification as AesRejectedNotification)?.notification?.ExportOperation
                        ?.businessRejectionType
            )?.value;

            const rejectionDateAndTime = (latestNotification as AesRejectedNotification)?.notification?.ExportOperation
                ?.rejectionDateAndTime;
            const date = rejectionDateAndTime ? new Date(rejectionDateAndTime) : null;
            const formattedDateАndTime =
                date && !isNaN(date.getTime()) ? `${dateStandardFormat(date)} ${getHour(date)}` : '-';

            return (
                <>
                    <ErrorRow label="Code:" value={details?.errorCode ?? '-'} />
                    <ErrorRow label="Message:" value={message ?? '-'} />
                    <ErrorRow label="Business Rule ID:" value={details?.errorReason ?? '-'} />
                    <ErrorRow label="Business Rule Details:" value={businessRuleDescription ?? '-'} />
                    <ErrorRow
                        label="Rejection Code:"
                        value={
                            (latestNotification as AesRejectedNotification)?.notification?.ExportOperation
                                ?.rejectionCode ?? '-'
                        }
                    />
                    <ErrorRow
                        label="Rejection Reason:"
                        value={
                            (latestNotification as AesRejectedNotification)?.notification?.ExportOperation
                                ?.rejectionReason ?? '-'
                        }
                    />
                    <ErrorRow label="Business Rejection Type:" value={businessRejectionType ?? '-'} />
                    <ListErrorsRows
                        section="Fields"
                        fields={fields ?? []}
                        handleOnClickField={handleOnClickField}
                        parseDeclaration={irelandExportValidationErrorsParser}
                        itemsPath={'goodsShipment.goodsItem'}
                    />
                    <ErrorRow label="Value:" value={details?.originalAttributeValue ?? '-'} />
                    <ErrorRow label="Date and time:" value={formattedDateАndTime} />
                </>
            );
        } else {
            return (
                <>
                    <ErrorRow label="Code:" value={details?.errorCode ?? '-'} />
                    <ErrorRow label="Value:" value={details?.originalAttributeValue ?? '-'} />
                    <ListErrorsRows
                        section="Fields"
                        fields={fields ?? []}
                        handleOnClickField={handleOnClickField}
                        parseDeclaration={irelandExportValidationErrorsParser}
                        itemsPath={'goodsShipment.goodsItem'}
                    />
                    {details?.errorColumnNumber && (
                        <ErrorRow label="Error Column Number:" value={details?.errorColumnNumber ?? '-'} />
                    )}
                    {details?.errorLineNumber && (
                        <ErrorRow label="Error Line Number:" value={details?.errorLineNumber ?? '-'} />
                    )}
                    {details?.errorText && <ErrorRow label="Error Text:" value={details?.errorText ?? '-'} />}
                    <ErrorRow label="External Field:" value={details?.errorPointer ?? '-'} />
                </>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [aesRules, fields, handleOnClickField]);

    return (
        <>
            <Title>Error details</Title>
            {detailsContent}
            <StyledHr />
            <span>
                <a
                    href={
                        rulesAndConditions?.url ??
                        'https://www.revenue.ie/en/online-services/support/software-developers/documents/aes/aes-business-rules-conditions.pdf'
                    }
                    rel="noreferrer"
                    target="_blank"
                >
                    {rulesAndConditions?.text ?? 'AES Business Rules and Conditions'}
                </a>
            </span>
        </>
    );
};

export default RevenueExportErrorDetails;
