import { FieldProps } from 'formik';
import { ReactElement, useMemo } from 'react';
import DeclarationField from 'views/declarations/common/DeclarationField';
import { getFormikProps } from 'views/declarations/utils/form-utils';
import { useTranslation } from 'react-i18next';
import FormInput from '../../../../../components/ui/composed/declarations/formInput/DeclarationInput';
import FormSelect from '../../../../../components/ui/composed/declarations/formSelect/DeclarationSelect';
import { ValidationSchema, validators } from '../validations/validations';
import { AvailableForms } from '../../../../../hooks/useHideEntities';
import useCodelists from '../../../../../hooks/useCodelists';
import useFormUtils from '../../../../../hooks/useFormUtils';

type Fields = 'id' | 'roleCode';

export const additionalSupplyChainActorBlockValidation: {
    childValidators: {
        'goodsShipment.AEOMutualRecognitionParty': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.AEOMutualRecognitionParty': {
            childValidators: {
                id: [validators.required(), validators.eori()],
                roleCode: [validators.required(), validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

export const cdsImportAdditionalSupplyChainActorBlockValidation: {
    childValidators: {
        'goodsShipment.AEOMutualRecognitionParty': ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        'goodsShipment.AEOMutualRecognitionParty': {
            childValidators: {
                id: [validators.eori()],
                roleCode: [validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

export const additionalSupplyChainActorBlockProductValidation: {
    childValidators: {
        AEOMutualRecognitionParty: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        AEOMutualRecognitionParty: {
            childValidators: {
                id: [validators.required(), validators.eori()],
                roleCode: [validators.required(), validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

export const cdsImportAdditionalSupplyChainActorBlockProductValidation: {
    childValidators: {
        AEOMutualRecognitionParty: ValidationSchema<Fields>;
    };
} = {
    childValidators: {
        AEOMutualRecognitionParty: {
            childValidators: {
                id: [validators.eori()],
                roleCode: [validators.maxLength(3)],
            },
            stopAtNull: true,
        },
    },
};

interface Props extends BlockProps<Fields>, DeclarationBlockProps<AvailableForms, Fields> {}

const AdditionalSupplyChainActorBlock = ({
    path = 'goodsShipment.AEOMutualRecognitionParty',
    declarationOptions,
}: Props): ReactElement => {
    const { t } = useTranslation('form');
    const { formTypeForCds: formType } = useFormUtils();
    const { cdsCodelists } = useCodelists();

    const ucc = useMemo(() => '3/37', []);

    return (
        <>
            <DeclarationField pathPrefix={path} name="id">
                {({ form, field }: FieldProps<any>) => (
                    <FormInput
                        refNumber={ucc}
                        required={declarationOptions?.[formType]?.fieldOptions?.id?.required}
                        label={t('identificationNumber')}
                        {...getFormikProps(field.name, form)}
                        condensed
                    />
                )}
            </DeclarationField>
            <DeclarationField pathPrefix={path} name="roleCode">
                {({ form, field }: FieldProps<any>) => (
                    <FormSelect
                        refNumber={ucc}
                        required={declarationOptions?.[formType]?.fieldOptions?.roleCode?.required}
                        label={t('roleCode')}
                        {...getFormikProps(field.name, form)}
                        selectOptions={cdsCodelists?.partySubRoleTypes}
                        condensed
                        codeValidation={[validators.maxLength(3)]}
                    />
                )}
            </DeclarationField>
        </>
    );
};

export default AdditionalSupplyChainActorBlock;
