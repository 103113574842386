import { validators } from '../../../uk/export/validations/validations';

const defaultCustomerBlockValidation = {
    childValidators: {
        id: [validators.eori()],
        name: [validators.maxLength(35)],
        'address.line': [validators.maxLength(35)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
    },
};

const eoriRequiredCustomerBlockValidation = {
    childValidators: {
        id: [validators.required(), validators.eori()],
        name: [validators.maxLength(70)],
        'address.line': [validators.maxLength(70)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
    },
};

const functionCodeCustomerBlockValidation = {
    childValidators: {
        id: [validators.eori()],
        functionCode: [validators.number(), validators.exact(1)],
        name: [validators.maxLength(70)],
        'address.line': [validators.maxLength(70)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
    },
};

const defaultLongerNameAndStreetCustomerBlockValidation = {
    childValidators: {
        id: [validators.eori()],
        name: [validators.maxLength(70)],
        'address.line': [validators.maxLength(70)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
    },
};

const defaultLongerNameCustomerBlockValidation = {
    childValidators: {
        id: [validators.eori()],
        name: [validators.maxLength(70)],
        'address.line': [validators.maxLength(35)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
    },
};

const phoneNumberCustomerBlockValidation = {
    childValidators: {
        id: [validators.eori()],
        name: [validators.maxLength(70)],
        'address.line': [validators.maxLength(70)],
        'address.cityName': [validators.maxLength(35)],
        'address.postcodeID': [validators.maxLength(9)],
        'address.countryCode': [validators.exact(2)],
        'communication.id': [validators.maxLength(50)],
    },
};

export const exportPartiesCardBlockValidation = {
    childValidators: {
        exporter: eoriRequiredCustomerBlockValidation,
        'goodsShipment.consignor': defaultLongerNameCustomerBlockValidation,
        'goodsShipment.consignee': defaultCustomerBlockValidation,
        declarant: eoriRequiredCustomerBlockValidation,
        agent: functionCodeCustomerBlockValidation,
        'consignment.carrier': defaultLongerNameAndStreetCustomerBlockValidation,
    },
};

export const exportItemPartiesCardBlockValidation = {
    childValidators: {
        'consignment.consignmentItem.consignor': defaultLongerNameCustomerBlockValidation,
    },
};

export const exportConsignmentItemsPartiesCardBlockValidation = {
    childValidators: {
        consignee: defaultCustomerBlockValidation,
    },
};

export const cdsImportH2PartiesCardValidation = {
    childValidators: {
        'goodsShipment.importer': defaultLongerNameAndStreetCustomerBlockValidation,
        declarant: eoriRequiredCustomerBlockValidation,
        agent: functionCodeCustomerBlockValidation,
    },
};

export const cdsImportH1PartiesCardValidation = {
    childValidators: {
        'goodsShipment.importer': defaultLongerNameAndStreetCustomerBlockValidation,
        exporter: defaultLongerNameAndStreetCustomerBlockValidation,
        declarant: eoriRequiredCustomerBlockValidation,
        agent: functionCodeCustomerBlockValidation,
        'goodsShipment.seller': phoneNumberCustomerBlockValidation,
        'goodsShipment.buyer': phoneNumberCustomerBlockValidation,
    },
};

export const cdsImportItemH1PartiesCardBlockValidation = {
    childValidators: {
        consignor: defaultLongerNameAndStreetCustomerBlockValidation,
        seller: phoneNumberCustomerBlockValidation,
        buyer: phoneNumberCustomerBlockValidation,
    },
};
