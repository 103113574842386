import { Button, Collapse, Divider, Typography } from 'antd';
import useDeclarations from 'hooks/useDeclarations';
import useProducts from 'hooks/useProducts';
import { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { goToDeclarationField } from 'views/declarations/utils/form-utils';
import {
    transformAgentPath,
    transformDangerousGoodsPath,
    transformGovernmentProcedurePath,
} from 'views/declarations/uk/export/utils';
import { groupBy, isEmpty } from 'lodash';
import {
    CdsExportNotification,
    CdsExportNotificationAdditionalInformation,
    CdsExportNotificationError,
} from 'store/declarations/uk/cds-declaration';
import parseValidationErrors from 'views/declarations/uk/parseValidationErrors';
import { getFieldName } from 'utils/validationParserUtils';
import { ButtonText, DetailsGrid, Error, FieldLink, MainGrid } from '../declaration-errors.styles';
import { getLastDateOfSubmit } from '../ens/IrelandEnsDeclarationErrors';
import { ShowRawDataDiv } from '../../DeclarationsNotificationsDrawer.styles';
import config from 'config/config';

const { Text, Title, Link } = Typography;

const transformField = (field: string | null | undefined) => {
    if (field == null) return null;

    const paths = field.split('.');

    paths.pop(); // the field ends in a dot so we remove the empty string at the end

    const fieldPaths: string[] = [];

    for (let path of paths) {
        if (path === 'declaration') continue;

        path = path.split('_').at(-1) as string;

        // Handle indexes by subtracting one to keep them zero indexed
        try {
            if (parseInt(path)) path = (parseInt(path) - 1).toString();
        } catch (e) {}

        fieldPaths.push(path);
    }

    return fieldPaths.join('.');
};

interface Props extends SpecificDeclarationErrorsProps {
    onClose?: () => void;
}

export const NotificationHeader = ({
    title,
    columnsStyle,
    columns,
}: {
    title?: string;
    columnsStyle?: string;
    columns?: string[];
}) => {
    return (
        <MainGrid columns={columnsStyle}>
            {columns ? (
                columns.map((column, index) => (
                    <Text strong key={`${column}-${index}`}>
                        {column}
                    </Text>
                ))
            ) : (
                <>
                    <Text strong>{title ?? 'CDS Error Code'}</Text>
                    <Text strong>Description</Text>
                    <Text strong></Text>
                </>
            )}
        </MainGrid>
    );
};

const ErrorsList = ({
    errors,
    error,
    onShowDetails,
    additionalInformation,
}: {
    errors?: CdsExportNotificationError[];
    error?: CdsExportNotificationError | null;
    onShowDetails?: (error: CdsExportNotificationError) => void;
    additionalInformation?: CdsExportNotificationAdditionalInformation[];
}) => {
    if (error) {
        const { code, message } = error;
        return (
            <MainGrid>
                <Error>{code ?? 'n/a'}</Error>
                <Text>{message ?? 'n/a'}</Text>
                {!error ? <ButtonText onClick={() => onShowDetails?.(error)}>Show details</ButtonText> : <span></span>}
            </MainGrid>
        );
    }

    const groupedErrors = groupBy(errors, 'groupId');
    const groupedAdditionalInformation = groupBy(additionalInformation, ({ field }) => field.split('.').at(3));

    return (
        <>
            {Object.entries(groupedErrors)?.map(([groupNumber, _errors], i) => {
                return (
                    <div>
                        <Text style={{ fontSize: '2rem' }} strong>
                            Group {groupNumber}
                        </Text>
                        {_errors.map((_error) => {
                            const { code, message } = _error;
                            return (
                                <MainGrid>
                                    <Error>{code ?? 'n/a'}</Error>
                                    <Text>{message ?? 'n/a'}</Text>
                                    {!error ? (
                                        <div>
                                            <ButtonText onClick={() => onShowDetails?.(_error)}>
                                                Show details
                                            </ButtonText>
                                        </div>
                                    ) : (
                                        <span></span>
                                    )}
                                </MainGrid>
                            );
                        })}
                        {groupedAdditionalInformation[groupNumber] && (
                            <div>
                                <Text strong style={{ fontSize: '1.75rem' }}>
                                    Additional Information
                                </Text>
                                <div style={{ display: 'grid', gap: '1rem', marginTop: '0.5rem' }}>
                                    {groupedAdditionalInformation[Number(groupNumber)].map(
                                        ({ field, statementDescription, statementCode, statementTypeCode }) => (
                                            <div>
                                                <div>
                                                    <Text strong>Field: </Text>
                                                    <Text>{field ?? '-'}</Text>
                                                </div>
                                                <div>
                                                    <Text strong>Code: </Text>
                                                    <Text>{statementCode ?? '-'}</Text>
                                                </div>
                                                <div>
                                                    <Text strong>Type Code: </Text>
                                                    <Text>{statementTypeCode ?? '-'}</Text>
                                                </div>
                                                <div>
                                                    <Text strong>Description: </Text>
                                                    <Text>{statementDescription ?? '-'}</Text>
                                                </div>
                                            </div>
                                        )
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                );
            })}
        </>
    );
};

const excludedFields: string[] = ['amendment'];
const isFieldNotExcluded = (field: string) => !excludedFields.includes(field);

interface DetailedErrorProps {
    error: CdsExportNotificationError | null;
    onBack: () => void;
    onGoToField?: () => void;
    errorsRender: ReactNode;
}
const DetailedError = ({
    error,
    onBack: handleBack,
    onGoToField: handleGoToField,
    errorsRender,
}: DetailedErrorProps) => {
    const uiField = useMemo(() => {
        const field = transformField(error?.field);
        const _uiField = field && getFieldName(parseValidationErrors, field, 'governmentAgencyGoodsItem');
        if (field && (!_uiField || _uiField === 'Unknown card - Unknown field')) return field;
        if (!field) return error?.field;
        return _uiField;
    }, [error?.field]);
    return (
        <>
            <Button onClick={handleBack}>Back to list</Button>
            <Title level={3} style={{ marginTop: '2rem', fontWeight: 700 }}>
                Error details
            </Title>
            <DetailsGrid style={{ marginTop: '2rem' }}>
                <Text strong>Field:</Text>
                {uiField && isFieldNotExcluded(uiField) ? (
                    <FieldLink onClick={handleGoToField}>{uiField}</FieldLink>
                ) : (
                    <Text>{uiField || 'n/a'}</Text>
                )}
            </DetailsGrid>
            <Divider />
            <>
                <NotificationHeader />
                {errorsRender}
            </>
            <div style={{ marginTop: '2rem', display: 'grid' }}>
                <Text strong>CDS Error Explanation</Text>
                <Text>{error?.explanation ?? 'n/a'}</Text>
            </div>
            <Link
                style={{ marginTop: '5rem', display: 'inline-block' }}
                href="https://view.officeapps.live.com/op/view.aspx?src=https:%2f%2fassets.publishing.service.gov.uk%2fgovernment%2fuploads%2fsystem%2fuploads%2fattachment_data%2ffile%2f1103977%2fcds_error_code_list-enhanced.ods&wdOrigin=BROWSELINK"
                target="_blank"
            >
                CDS Error Code List
            </Link>
        </>
    );
};

interface PreviousErrorsProps {
    notifications?: CdsExportNotification[];
}
const PreviousErrors = ({ notifications }: PreviousErrorsProps): ReactElement | null => {
    const { declarationId } = useParams();
    const { declarationHistory, getDeclarationHistory } = useDeclarations();
    const lastDateOfSubmit = useMemo(() => getLastDateOfSubmit(declarationHistory), [declarationHistory]);
    const [error, setError] = useState<CdsExportNotificationError | null>(null);

    useEffect(() => {
        if (declarationId) getDeclarationHistory(declarationId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const previousNotificationsWithErrors = useMemo(() => {
        const _notifications = notifications && [...notifications];
        _notifications?.pop();
        _notifications?.reverse();
        return _notifications?.filter(
            (notification) =>
                lastDateOfSubmit &&
                notification?.issueDateTime &&
                !isEmpty(notification.validationMessages) &&
                lastDateOfSubmit < notification?.issueDateTime
        );
    }, [notifications, lastDateOfSubmit]);

    const showDetails = (error: CdsExportNotificationError) => {
        setError(error);
    };

    const defaultActiveKey = useMemo(() => {
        return Array(previousNotificationsWithErrors?.length)
            .fill(null)
            .map((_, index) => index);
    }, [previousNotificationsWithErrors?.length]);

    const notificationsList = useMemo(() => {
        if (!previousNotificationsWithErrors || !previousNotificationsWithErrors.length) return 'No previous errors';
        return (
            <Collapse defaultActiveKey={defaultActiveKey}>
                {previousNotificationsWithErrors?.map((notification, index) => {
                    return (
                        <Collapse.Panel
                            header={new Date(notification.issueDateTime ?? '').toLocaleString()}
                            key={index}
                        >
                            {error ? (
                                <DetailedError
                                    error={error}
                                    onBack={() => setError(null)}
                                    errorsRender={<ErrorsList error={error} />}
                                />
                            ) : (
                                <>
                                    <NotificationHeader />
                                    <ErrorsList errors={notification.validationMessages} onShowDetails={showDetails} />
                                </>
                            )}
                        </Collapse.Panel>
                    );
                })}
            </Collapse>
        );
    }, [defaultActiveKey, error, previousNotificationsWithErrors]);

    if (isEmpty(notificationsList)) return null;

    return (
        <Collapse defaultActiveKey="1">
            <Collapse.Panel header="Previous errors" key={1}>
                {notificationsList}
            </Collapse.Panel>
        </Collapse>
    );
};

const UkExportDeclarationErrors = ({
    errors,
    additionalInformation,
    onClose,
    notifications,
    latestNotification,
    showRawData: showRawDataProp = false,
}: Props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const inViewOnly = useMemo(() => location.pathname.includes('view-only'), [location.pathname]);
    const { products } = useProducts();
    const { declaration } = useDeclarations();

    const [error, setError] = useState<CdsExportNotificationError | null>(null);
    const [showRawData, setShowRawData] = useState<boolean>(showRawDataProp);

    const showDetails = (error: CdsExportNotificationError) => {
        setError(error);
    };
    const goBack = () => {
        setError(null);
    };
    const hasSelectedError = useMemo(() => !!error, [error]);
    const handleGoToField = () => {
        let fieldName = transformField(error?.field); // example: goodsShipment.governmentAgencyGoodsItem.1.governmentProcedure.1.currentCode
        if (fieldName == null) return;

        if (fieldName.includes('governmentAgencyGoodsItem')) {
            const productNumber = Number(fieldName.split('.')[2]);
            const product = products?.list.at(productNumber);

            fieldName = fieldName.split('.').slice(3).join('.');

            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}/products/${product!.id}`);
        } else if (location.pathname.split('/').at(-1) !== declaration?.id) {
            navigate(`/declarations/${declaration?.id}${inViewOnly ? '/view-only' : ''}`);
        }

        onClose?.();

        if (fieldName.includes('dangerousGoods')) {
            fieldName = transformDangerousGoodsPath(fieldName);
        }
        if (fieldName.includes('agent')) {
            fieldName = transformAgentPath(fieldName);
        }

        goToDeclarationField(fieldName, 300);
    };

    if (hasSelectedError) {
        return (
            <DetailedError
                error={error}
                onBack={goBack}
                onGoToField={handleGoToField}
                errorsRender={<ErrorsList error={error} />}
            />
        );
    }

    if (!!showRawData) {
        return (
            <>
                {!showRawDataProp && <Button onClick={() => setShowRawData(false)}>Back to list</Button>}
                <Title level={3} style={{ marginTop: '2rem', fontWeight: 700 }}>
                    Error details
                </Title>
                <div>{latestNotification?.rawMessage}</div>
            </>
        );
    }

    return (
        <>
            {config.isPIT && (
                <ShowRawDataDiv>
                    <ButtonText onClick={() => setShowRawData(true)}>Show raw data</ButtonText>
                </ShowRawDataDiv>
            )}

            <NotificationHeader />
            <ErrorsList errors={errors} additionalInformation={additionalInformation} onShowDetails={showDetails} />
            <div style={{ marginTop: '3rem' }}>
                <PreviousErrors notifications={notifications} />
            </div>
        </>
    );
};

export default UkExportDeclarationErrors;
