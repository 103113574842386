import { FieldInputProps } from 'formik';
import { FC, ReactElement, useMemo } from 'react';
import moment from 'moment';
import { AStyledDatePicker } from 'components/ui/composed/declarations/formDatepicker/DeclarationDatepicker.styles';
import { parseToDate } from './utils';

interface DatePickerProps {
    fieldProps: FieldInputProps<any>;
    showTime?: boolean;
    onFocus?: React.FocusEventHandler<HTMLElement>;
    onBlur?: React.FocusEventHandler<HTMLElement>;
    onChange?: (value: moment.Moment | null) => void;
    id?: string;
    condensed?: boolean;
    disabled?: boolean;
    status?: '' | 'warning' | 'error' | undefined;
    mirrored?: boolean;
    getPopupContainer?: ((triggerNode: HTMLElement) => HTMLElement) | undefined;
}

const toValidMoment = (value: string | Date | moment.Moment | null | undefined): moment.Moment | null => {
    if (typeof value === 'string') {
        if (value === '') return moment();

        /**
         * The BE appends [UTC] at the end of ISO date time.
         * With this we remove it.
         */
        value = value.replace('[UTC]', '');

        const m = moment(value);
        if (m.isValid()) return m;

        const date = parseToDate(value, ['yyyyMMddHHmm']);
        if (!date) throw new Error(`Could not parse to date. Value: ${value}`);

        return moment(date);
    } else if (value instanceof Date) {
        if (!isNaN(value.getTime())) throw new Error('Invalid date');
        return moment(value);
    } else if (value == null) {
        return null;
    } else {
        return value;
    }
};

const DDatePicker: FC<DatePickerProps> = ({
    fieldProps,
    showTime,
    onFocus,
    onBlur,
    onChange,
    id,
    condensed,
    disabled,
    status,
    mirrored,
    getPopupContainer,
}): ReactElement => {
    const value = useMemo(() => toValidMoment(fieldProps.value), [fieldProps.value]);

    return (
        <AStyledDatePicker
            id={id}
            disabled={disabled}
            allowClear
            format={showTime ? 'DD-MM-YYYY HH:mm' : 'DD-MM-YYYY'}
            showTime={showTime ? { defaultValue: moment('00:00:00', 'HH:mm:ss') } : undefined}
            defaultValue={fieldProps.value && new Date(fieldProps.value)}
            value={value}
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            condensed={condensed}
            status={status}
            mirrored={mirrored}
            name={fieldProps.name}
            getPopupContainer={
                (getPopupContainer as (triggerNode: HTMLElement) => HTMLElement) || ((trigger) => trigger.parentElement)
            }
        />
    );
};

export default DDatePicker;
