import { FC } from 'react';
import { FormikProps, FormikProvider } from 'formik';
import useCodelists from '../../../../hooks/useCodelists';
import NewFormCard, { FormCardContainer } from '../../common/cards/NewFormCard';
import DeclarationInput from 'components/ui/composed/declarations/formInput/DeclarationInput';
import DeclarationSelect from 'components/ui/composed/declarations/formSelect/DeclarationSelect';
import { getFormikProps, getFullCodelistValue } from '../../utils/form-utils';
import { validators } from '../../uk/export/validations/validations';
import DeclarationNumberInput from '../../../../components/ui/composed/declarations/formInput/DeclarationNumberInput';
import DeclarationDatepicker from '../../../../components/ui/composed/declarations/formDatepicker/DeclarationDatepicker';
import MultipleItemsCard from '../../common/MultipleItemsCard';
import addPathPrefix from '../../../../utils/addPathPrefix';
import PartiesCard from '../../common/parties/PartiesCard';
import CurrencySelect from '../../../../components/ui/composed/declarations/CurrencySelect';
import MultipleDeclarationField from '../../common/MultipleDeclarationField';
import DeclarationPhoneNumber from 'components/ui/composed/formPhoneNumber/DeclarationPhoneNumber';
import CardSection from '../../common/cards/CardSection';
import CountrySelect from '../../../../components/ui/composed/declarations/CountrySelect';
import useHideEntities from '../../../../hooks/useHideEntities';
import useNumberOfItemsModal from '../../common/declaration-view/utils/useNumberOfItemsModal';
import useProducts from '../../../../hooks/useProducts';
import { useOutletContext } from 'react-router-dom';
import { NctsDeclaration } from '../../../../store/declarations/ireland/ncts-declaration';
import { notification } from 'antd';

interface Props {
    formik: FormikProps<any>;
    customerId: string | undefined;
}

const UkNctsForm: FC<Props> = ({ formik, customerId }) => {
    const { isFormType } = useHideEntities();
    const { createUkNctsProduct, listUkNctsProducts } = useProducts();
    const { products } = useProducts();
    const { ukNctsCodelists, getUkUnLocodeExtendedCodelist, ukUnloPagination } = useCodelists();

    const canLoadMore = ukUnloPagination ? ukUnloPagination?.count / (ukUnloPagination?.page * 20) > 0 : false;

    const onLoadMore = () => {
        if (!canLoadMore) return;
        const nextPage = (ukUnloPagination?.page ?? 0) + 1;
        getUkUnLocodeExtendedCodelist(customerId, {
            page: nextPage,
            size: 20,
        });
    };

    const { saveAsDraft } =
        useOutletContext<{
            saveAsDraft: (withNotification: boolean, data?: unknown) => Promise<unknown>;
        }>() ?? {};

    const [handleNumberOfItems, modalContextHolder] = useNumberOfItemsModal({
        createProduct: createUkNctsProduct,
        listProducts: listUkNctsProducts,
        saveAsDraft,
        initialData: {
            houseConsignmentId: (formik.values as NctsDeclaration)?.consignment?.houseConsignment?.[0]?.id,
        },
    });

    const getAssignedItemsNumbers = (houseConsignmentItems: any) => {
        return houseConsignmentItems
            ?.reduce((acc: number[], item: any) => {
                const indexOf = products?.list?.findIndex((i: any) => i.id === item.id);
                if (indexOf !== undefined && indexOf !== -1) acc.push(indexOf + 1);
                return acc;
            }, [])
            .sort();
    };

    const handleHouseConsignmentBeforeDelete = (index: number) => {
        const thisHouseConsignmentItems = formik.values?.consignment?.houseConsignment?.[index].consignmentItem;

        if (!thisHouseConsignmentItems?.length) return;

        const itemsNumbers = getAssignedItemsNumbers(thisHouseConsignmentItems);

        notification.warning({
            message: `Please change or remove link of all consignment items (nr. ${itemsNumbers.join(
                ', '
            )}) attached to this house consignment before removing it.`,
        });
        return false;
    };

    return (
        <FormikProvider value={formik}>
            <NewFormCard title="Transit Operation">
                <FormCardContainer>
                    <DeclarationInput
                        required
                        label="Message Sender"
                        {...getFormikProps('messageSender', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        required
                        label="Declaration type"
                        {...getFormikProps('transitOperation.declarationType', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.declarationType}
                        codeValidation={[validators.maxLength(5)]}
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationSelect
                        required
                        label="Additional declaration type"
                        {...getFormikProps('transitOperation.additionalDeclarationType', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.declarationTypeAdditional}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        label="TIR Carnet number"
                        {...getFormikProps('transitOperation.tirCarnetNumber', formik)}
                        condensed
                        hidden={isFormType(['D2', 'D3', 'D4'])}
                    />
                    <DeclarationDatepicker
                        label="Presentation of the goods date"
                        {...getFormikProps('transitOperation.presentationOfTheGoodsDateAndTime', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        required
                        label="Security"
                        {...getFormikProps('transitOperation.security', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.declarationTypeSecurity}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D3', 'D4'])}
                    />
                    <DeclarationSelect
                        required
                        label="Reduced dataset indicator"
                        {...getFormikProps('transitOperation.reducedDatasetIndicator', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.flag}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D3', 'D4'])}
                    />

                    <DeclarationSelect
                        label="Specific circumstance indicator"
                        {...getFormikProps('transitOperation.specificCircumstanceIndicator', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.specificCircumstanceIndicatorCode}
                        codeValidation={[validators.exact(3)]}
                        hidden={isFormType(['D3', 'D4'])}
                    />

                    <DeclarationSelect
                        label="Communication language at departure"
                        {...getFormikProps('transitOperation.communicationLanguageAtDeparture', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.languageByCustoms}
                        codeValidation={[validators.exact(2)]}
                    />

                    <DeclarationSelect
                        required
                        label="Binding itinerary"
                        {...getFormikProps('transitOperation.bindingItinerary', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.flag}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D3', 'D4'])}
                    />

                    <DeclarationDatepicker
                        label="Limit date"
                        {...getFormikProps('transitOperation.limitDate', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />

                    <DeclarationNumberInput
                        label="Number of Items"
                        {...getFormikProps(`numberOfItems`, formik)}
                        fieldEvents={{
                            onBlur(value) {
                                handleNumberOfItems(Number(value));
                            },
                        }}
                        required
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Authorisation"
                path="authorisation"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, ukNctsCodelists?.authorisationTypeDeparture),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.maxLength(4)]}
                            condensed
                            selectOptions={ukNctsCodelists?.authorisationTypeDeparture}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Customs Office Of Departure">
                <FormCardContainer>
                    <DeclarationSelect
                        required
                        label="Reference number"
                        {...getFormikProps('customsOfficeOfDeparture.referenceNumber', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.customsOfficeDeparture}
                        codeValidation={[validators.exact(8)]}
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Customs Office Of Destination Declared" hidden={isFormType(['D4'])}>
                <FormCardContainer>
                    <DeclarationSelect
                        required
                        label="Reference number"
                        {...getFormikProps('customsOfficeOfDestinationDeclared.referenceNumber', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.customsOfficeDestination}
                        codeValidation={[validators.exact(8)]}
                        hidden={isFormType(['D4'])}
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Customs Office Of Transit Declared"
                path="customsOfficeOfTransitDeclared"
                initialValues={{ referenceNumber: '', arrivalDateAndTimeEstimated: '' }}
                list={(list) => [
                    {
                        field: 'Reference Number',
                        value: getFullCodelistValue(list.referenceNumber, ukNctsCodelists?.customsOfficeTransit),
                        required: true,
                    },
                    {
                        field: 'Arrival date and time (estimated)',
                        value: list.arrivalDateAndTimeEstimated,
                    },
                ]}
                condensed
                hidden={isFormType(['D3', 'D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            selectOptions={ukNctsCodelists?.customsOfficeTransit}
                            codeValidation={[validators.exact(8)]}
                            hidden={isFormType(['D3', 'D4'])}
                        />
                        <DeclarationDatepicker
                            showTime
                            label="Arrival date and time (estimated)"
                            {...getFormikProps(addPathPrefix(path, 'arrivalDateAndTimeEstimated'), formik)}
                            condensed
                            hidden={isFormType(['D3', 'D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Customs Office Of Exit For Transit Declared" hidden={isFormType(['D3', 'D4'])}>
                <FormCardContainer>
                    <MultipleDeclarationField parent="customsOfficeOfExitForTransitDeclared" name="referenceNumber">
                        {(fieldProps, controls) => (
                            <DeclarationSelect
                                required
                                label="Customs Office Of Exit For Transit Declared - Reference Number"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                selectOptions={ukNctsCodelists?.customsOfficeTransitExit}
                                codeValidation={[validators.exact(8)]}
                                hidden={isFormType(['D3', 'D4'])}
                            />
                        )}
                    </MultipleDeclarationField>
                </FormCardContainer>
            </NewFormCard>

            <PartiesCard
                getFieldProps={formik.getFieldProps}
                getFieldMeta={formik.getFieldMeta}
                getFieldHelpers={formik.getFieldHelpers}
                parties={[
                    {
                        path: 'representative',
                        header: 'Representative',
                        eoriRequired: true,
                        hasRepresentativeStatus: true,
                        refNumber: undefined,
                        hasAddress: false,
                        contactPerson: {
                            present: true,
                        },
                    },
                    {
                        path: 'holderOfTheTransitProcedure',
                        header: 'Holder Of The Transit Procedure',
                        refNumber: undefined,
                        contactPerson: {
                            present: true,
                        },
                    },
                    {
                        path: 'consignment.carrier',
                        header: 'Carrier',
                        refNumber: undefined,
                        hasAddress: false,
                        eoriRequired: true,
                        contactPerson: {
                            present: true,
                        },
                        hidden: isFormType(['D4']),
                    },
                    {
                        path: 'consignment.consignor',
                        header: 'Consignor',
                        refNumber: undefined,
                        contactPerson: {
                            present: true,
                        },
                        hidden: isFormType(['D2', 'D3', 'D4']),
                    },
                    {
                        path: 'consignment.consignee',
                        header: 'Consignee',
                        refNumber: undefined,
                        hidden: isFormType(['D4']),
                    },
                ]}
                paths={{
                    address: {
                        city: 'address.city',
                        country: 'address.country',
                        name: 'name',
                        postCode: 'address.postcode',
                        streetAndNumber: 'address.streetAndNumber',
                    },
                    eori: 'identificationNumber',
                    contactPerson: {
                        name: 'contactPerson.name',
                        phoneNumber: 'contactPerson.phoneNumber',
                        email: 'contactPerson.emailAddress',
                    },
                    additional: {
                        representativeStatus: 'status',
                    },
                }}
                condensed
            />

            <MultipleItemsCard
                title="Guarantee"
                path="guarantee"
                required
                initialValues={{ guaranteeType: '', otherGuaranteeReference: '', guaranteeReference: [] }}
                list={(list) => [
                    {
                        field: 'Guarantee type',
                        value: getFullCodelistValue(list.guaranteeType, ukNctsCodelists?.guaranteeType),
                    },
                    {
                        field: 'Other guarantee reference',
                        value: list.otherGuaranteeReference,
                    },
                    {
                        field: 'Guarantee Reference',
                        value: list.guaranteeReference?.length.toString() ?? '0',
                    },
                ]}
                condensed
            >
                {(path) => (
                    <>
                        <FormCardContainer>
                            <DeclarationSelect
                                label="Guarantee type"
                                {...getFormikProps(addPathPrefix(path, 'guaranteeType'), formik)}
                                required
                                condensed
                                selectOptions={ukNctsCodelists?.guaranteeType}
                                codeValidation={[validators.exact(1)]}
                                hidden={isFormType(['D3', 'D4'])}
                            />
                            <DeclarationInput
                                label="Other guarantee reference"
                                {...getFormikProps(addPathPrefix(path, 'otherGuaranteeReference'), formik)}
                                condensed
                            />
                        </FormCardContainer>
                        <MultipleItemsCard
                            style={{ marginTop: 10 }}
                            title="Guarantee Reference"
                            path="guaranteeReference"
                            initialValues={{ grn: '', accessCode: '', amountToBeCovered: '', currency: '' }}
                            list={(list) => [
                                {
                                    field: 'GRN',
                                    value: list.grn,
                                },
                                {
                                    field: 'Access code',
                                    value: list.accessCode,
                                },
                                {
                                    field: 'Amount to be covered',
                                    value: list.amountToBeCovered,
                                },
                                {
                                    field: 'Currency',
                                    value: list.currency,
                                },
                            ]}
                            condensed
                            hidden={isFormType(['D3', 'D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationInput
                                        label="GRN"
                                        {...getFormikProps(addPathPrefix(path, 'grn'), formik)}
                                        condensed
                                        hidden={isFormType(['D3', 'D4'])}
                                    />
                                    <DeclarationInput
                                        label="Access code"
                                        {...getFormikProps(addPathPrefix(path, 'accessCode '), formik)}
                                        condensed
                                        hidden={isFormType(['D3', 'D4'])}
                                    />
                                    <DeclarationNumberInput
                                        label="Amount to be covered"
                                        {...getFormikProps(addPathPrefix(path, 'amountToBeCovered'), formik)}
                                        condensed
                                        hidden={isFormType(['D3', 'D4'])}
                                    />
                                    <CurrencySelect
                                        label="Currency"
                                        {...getFormikProps(addPathPrefix(path, 'currency'), formik)}
                                        condensed
                                        hidden={isFormType(['D3', 'D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                    </>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Consignment">
                <FormCardContainer>
                    <CountrySelect
                        label="Country of dispatch"
                        {...getFormikProps('consignment.countryOfDispatch', formik)}
                        condensed
                        hidden={isFormType(['D2', 'D3', 'D4'])}
                    />
                    <CountrySelect
                        label="Country of destination"
                        {...getFormikProps('consignment.countryOfDestination', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationSelect
                        required
                        label="Container indicator"
                        {...getFormikProps('consignment.containerIndicator', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.flag}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationSelect
                        label="Inland mode of transport"
                        {...getFormikProps('consignment.inlandModeOfTransport', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.transportModeCode}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D2', 'D3', 'D4'])}
                    />
                    <DeclarationSelect
                        label="Mode of transport at the border"
                        {...getFormikProps('consignment.modeOfTransportAtTheBorder', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.transportModeCode}
                        codeValidation={[validators.exact(1)]}
                        hidden={isFormType(['D3', 'D4'])}
                    />
                    <DeclarationNumberInput
                        required
                        label="Gross mass"
                        {...getFormikProps('consignment.grossMass', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <DeclarationInput
                        label="Reference number UCR"
                        {...getFormikProps('consignment.referenceNumberUCR', formik)}
                        condensed
                        hidden={isFormType(['D4'])}
                    />
                    <MultipleDeclarationField
                        parent="consignment.countryOfRoutingOfConsignment"
                        name="country"
                        hidden={isFormType(['D3', 'D4'])}
                    >
                        {(fieldProps, controls) => (
                            <CountrySelect
                                required
                                label="Country of Routing of Consignment"
                                {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                multipleF={controls}
                                condensed
                                hidden={isFormType(['D3', 'D4'])}
                            />
                        )}
                    </MultipleDeclarationField>

                    <DeclarationSelect
                        required
                        label="Transport Charges - Method of payment"
                        {...getFormikProps('consignment.transportCharges.methodOfPayment', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.transportChargesMethodOfPayment}
                        codeValidation={[validators.exact(1), validators.required()]}
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Additional Supply Chain Actor"
                path="consignment.additionalSupplyChainActor"
                initialValues={{ role: '', identificationNumber: '' }}
                list={(list) => [
                    {
                        field: 'Role',
                        value: getFullCodelistValue(list.role, ukNctsCodelists?.additionalSupplyChainActorRoleCode),
                    },
                    {
                        field: 'Identification number',
                        value: list.identificationNumber,
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Role"
                            {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                            required
                            condensed
                            selectOptions={ukNctsCodelists?.additionalSupplyChainActorRoleCode}
                            codeValidation={[validators.maxLength(3)]}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            required
                            label="Identification number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Equipment"
                path="consignment.transportEquipment"
                initialValues={{
                    containerIdentificationNumber: '',
                    numberOfSeals: '',
                    seal: [],
                    goodsReference: [],
                }}
                list={(list) => [
                    {
                        field: 'Container identification number',
                        value: list.containerIdentificationNumber,
                    },
                    {
                        field: 'Number of seals',
                        value: list.numberOfSeals,
                    },
                    {
                        field: 'Seal - Identifier',
                        value: list.seal?.length.toString() ?? '0',
                    },
                    {
                        field: 'Goods Reference - Declaration goods item number',
                        value: list.goodsReference?.length.toString() ?? '0',
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Container identification number"
                            {...getFormikProps(addPathPrefix(path, 'containerIdentificationNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            required
                            label="Number of seals"
                            {...getFormikProps(addPathPrefix(path, 'numberOfSeals'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <MultipleDeclarationField
                            parent={addPathPrefix(path, 'seal')}
                            name="identifier"
                            hidden={isFormType(['D4'])}
                        >
                            {(fieldProps, controls) => (
                                <DeclarationInput
                                    required
                                    label="Seal - Identifier"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    multipleF={controls}
                                    condensed
                                    hidden={isFormType(['D4'])}
                                />
                            )}
                        </MultipleDeclarationField>
                        <MultipleDeclarationField
                            parent={addPathPrefix(path, 'goodsReference')}
                            name="declarationGoodsItemNumber"
                            hidden={isFormType(['D4'])}
                        >
                            {(fieldProps, controls) => (
                                <DeclarationInput
                                    required
                                    label="Goods Reference - Declaration goods item number"
                                    {...getFormikProps(fieldProps.field.name, fieldProps.form)}
                                    multipleF={controls}
                                    condensed
                                    hidden={isFormType(['D4'])}
                                />
                            )}
                        </MultipleDeclarationField>
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Location Of Goods">
                <FormCardContainer>
                    <DeclarationSelect
                        required
                        label="Type of location"
                        {...getFormikProps('consignment.locationOfGoods.typeOfLocation', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.typeOfLocation}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationSelect
                        required
                        label="Qualifier of identification"
                        {...getFormikProps('consignment.locationOfGoods.qualifierOfIdentification', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.qualifierOfTheIdentification}
                        codeValidation={[validators.exact(1)]}
                    />
                    <DeclarationInput
                        label="Authorisation number"
                        {...getFormikProps('consignment.locationOfGoods.authorisationNumber', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Additional identifier"
                        {...getFormikProps('consignment.locationOfGoods.additionalIdentifier', formik)}
                        condensed
                    />
                    <DeclarationSelect
                        label="UN LOCODE"
                        {...getFormikProps('consignment.locationOfGoods.uNLocode', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.unLocodeExtended}
                        canLoadMore={canLoadMore}
                        onLoadMore={onLoadMore}
                        codeValidation={[validators.maxLength(17)]}
                    />
                    <DeclarationSelect
                        required
                        label="Customs Office - Reference number"
                        {...getFormikProps('consignment.locationOfGoods.customsOffice.referenceNumber', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.customsOfficeDeparture}
                        codeValidation={[validators.exact(8)]}
                    />
                    <DeclarationInput
                        required
                        label="GNSS - Latitude"
                        {...getFormikProps('consignment.locationOfGoods.gnss.latitude', formik)}
                        condensed
                    />
                    <DeclarationInput
                        required
                        label="GNSS - Longitude"
                        {...getFormikProps('consignment.locationOfGoods.gnss.longitude', formik)}
                        condensed
                    />
                    <DeclarationInput
                        required
                        label="Economic Operator - Identification Number"
                        {...getFormikProps(`consignment.locationOfGoods.economicOperator.identificationNumber`, formik)}
                        condensed
                    />
                    <CardSection title="Address">
                        <DeclarationInput
                            required
                            label="Street and Number"
                            {...getFormikProps(`consignment.locationOfGoods.address.streetAndNumber`, formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="Postcode"
                            {...getFormikProps(`consignment.locationOfGoods.address.postcode`, formik)}
                            condensed
                        />
                        <DeclarationInput
                            required
                            label="City"
                            {...getFormikProps(`consignment.locationOfGoods.address.city`, formik)}
                            condensed
                        />
                        <CountrySelect
                            required
                            label="Country"
                            {...getFormikProps(`consignment.locationOfGoods.address.country`, formik)}
                            selectOptions={ukNctsCodelists?.countryCodesCommonTransit}
                            condensed
                        />
                    </CardSection>
                    <CardSection title="Postcode Address">
                        <DeclarationInput
                            label="House Number"
                            {...getFormikProps(`consignment.locationOfGoods.postcodeAddress.houseNumber`, formik)}
                            condensed
                        />
                        <DeclarationInput
                            required
                            label="Postcode"
                            {...getFormikProps(`consignment.locationOfGoods.postcodeAddress.postcode`, formik)}
                            condensed
                        />
                        <CountrySelect
                            required
                            label="Country"
                            {...getFormikProps(`consignment.locationOfGoods.postcodeAddress.country`, formik)}
                            selectOptions={ukNctsCodelists?.countryAddressPostcodeBased}
                            condensed
                        />
                    </CardSection>
                    <CardSection title="Contact Person">
                        <DeclarationInput
                            required
                            label="Name"
                            {...getFormikProps(`consignment.locationOfGoods.contactPerson.name`, formik)}
                            condensed
                        />
                        <DeclarationPhoneNumber
                            required
                            label="Phone Number"
                            {...getFormikProps(`consignment.locationOfGoods.contactPerson.phoneNumber`, formik)}
                            condensed
                        />
                        <DeclarationInput
                            label="E-mail Address"
                            {...getFormikProps(`consignment.locationOfGoods.contactPerson.emailAddress`, formik)}
                            condensed
                        />
                    </CardSection>
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Departure Transport Means"
                path="consignment.departureTransportMeans"
                initialValues={{ typeOfIdentification: '', identificationNumber: '', nationality: '' }}
                list={(list) => [
                    {
                        field: 'Type of Identification',
                        value: getFullCodelistValue(
                            list.typeOfIdentification,
                            ukNctsCodelists?.typeOfIdentificationOfMeansOfTransport
                        ),
                    },
                    { field: 'Identification Number', value: list.identificationNumber },
                    {
                        field: 'Nationality',
                        value: getFullCodelistValue(list.nationality, ukNctsCodelists?.nationality),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Type of Identification"
                            {...getFormikProps(addPathPrefix(path, 'typeOfIdentification'), formik)}
                            codeValidation={[validators.number(), validators.exact(2)]}
                            condensed
                            selectOptions={ukNctsCodelists?.typeOfIdentificationOfMeansOfTransport}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(addPathPrefix(path, 'nationality'), formik)}
                            codeValidation={[validators.exact(2)]}
                            condensed
                            selectOptions={ukNctsCodelists?.nationality}
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Active Border Transport Means"
                path="consignment.activeBorderTransportMeans"
                initialValues={{
                    customsOfficeAtBorderReferenceNumber: '',
                    typeOfIdentification: '',
                    identificationNumber: '',
                    nationality: '',
                    conveyanceReferenceNumber: '',
                }}
                list={(list) => [
                    {
                        field: 'Customs office at border reference number',
                        value: getFullCodelistValue(
                            list.customsOfficeAtBorderReferenceNumber,
                            ukNctsCodelists?.customsOfficeDestination
                        ),
                    },
                    {
                        field: 'Type of identification',
                        value: getFullCodelistValue(
                            list.typeOfIdentification,
                            ukNctsCodelists?.typeOfIdentificationofMeansOfTransportActive
                        ),
                    },
                    { field: 'Identification Number', value: list.identificationNumber },
                    { field: 'Nationality', value: list.nationality },
                    { field: 'Conveyance reference number', value: list.conveyanceReferenceNumber },
                ]}
                condensed
                hidden={isFormType(['D3'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Customs office at border reference number"
                            {...getFormikProps(addPathPrefix(path, 'customsOfficeAtBorderReferenceNumber'), formik)}
                            condensed
                            selectOptions={ukNctsCodelists?.customsOfficeDestination}
                            codeValidation={[validators.maxLength(3)]}
                            hidden={isFormType(['D3'])}
                        />
                        <DeclarationSelect
                            label="Type of identification"
                            {...getFormikProps(addPathPrefix(path, 'typeOfIdentification'), formik)}
                            condensed
                            selectOptions={ukNctsCodelists?.typeOfIdentificationofMeansOfTransportActive}
                            codeValidation={[validators.exact(2)]}
                            hidden={isFormType(['D3'])}
                        />
                        <DeclarationInput
                            label="Identification Number"
                            {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                            condensed
                            hidden={isFormType(['D3'])}
                        />
                        <DeclarationSelect
                            label="Nationality"
                            {...getFormikProps(addPathPrefix(path, 'nationality'), formik)}
                            condensed
                            selectOptions={ukNctsCodelists?.nationality}
                            codeValidation={[validators.exact(2)]}
                            hidden={isFormType(['D3'])}
                        />
                        <DeclarationInput
                            label="Conveyance reference number"
                            {...getFormikProps(addPathPrefix(path, 'conveyanceReferenceNumber'), formik)}
                            condensed
                            hidden={isFormType(['D3'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Place of Loading">
                <FormCardContainer>
                    <DeclarationSelect
                        label="UN LOCODE"
                        {...getFormikProps('consignment.placeOfLoading.unLocode', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.unLocodeExtended}
                        canLoadMore={canLoadMore}
                        onLoadMore={onLoadMore}
                        codeValidation={[validators.maxLength(17)]}
                    />
                    <CountrySelect
                        label="Country"
                        {...getFormikProps('consignment.placeOfLoading.country', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Location"
                        {...getFormikProps('consignment.placeOfLoading.location', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <NewFormCard title="Place of Unloading">
                <FormCardContainer>
                    <DeclarationSelect
                        label="UN LOCODE"
                        {...getFormikProps('consignment.placeOfUnloading.unLocode', formik)}
                        condensed
                        selectOptions={ukNctsCodelists?.unLocodeExtended}
                        canLoadMore={canLoadMore}
                        onLoadMore={onLoadMore}
                        codeValidation={[validators.maxLength(17)]}
                    />
                    <CountrySelect
                        label="Country"
                        {...getFormikProps('consignment.placeOfUnloading.country', formik)}
                        condensed
                    />
                    <DeclarationInput
                        label="Location"
                        {...getFormikProps('consignment.placeOfUnloading.location', formik)}
                        condensed
                    />
                </FormCardContainer>
            </NewFormCard>

            <MultipleItemsCard
                title="Previous Document"
                path="consignment.previousDocument"
                initialValues={{ referenceNumber: '', type: '', complementOfInformation: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, ukNctsCodelists?.previousDocumentType),
                    },
                    { field: 'Complement of information', value: list.complementOfInformation },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={ukNctsCodelists?.previousDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Complement of information"
                            {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                            condensed
                            hidden={isFormType(['D3', 'D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Supporting Document"
                path="consignment.supportingDocument"
                initialValues={{
                    referenceNumber: '',
                    type: '',
                    documentLineItemNumber: '',
                    complementOfInformation: '',
                }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, ukNctsCodelists?.supportingDocumentType),
                    },
                    { field: 'Complement of information', value: list.complementOfInformation },
                    { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            required
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={ukNctsCodelists?.supportingDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Complement of information"
                            {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                            condensed
                            hidden={isFormType(['D2', 'D3', 'D4'])}
                        />
                        <DeclarationNumberInput
                            label="Document Line Item Number"
                            {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Transport Document"
                path="consignment.transportDocument"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, ukNctsCodelists?.transportDocumentType),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            required
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationSelect
                            required
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={ukNctsCodelists?.transportDocumentType}
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Reference"
                path="consignment.additionalReference"
                initialValues={{ referenceNumber: '', type: '' }}
                list={(list) => [
                    { field: 'Reference Number', value: list.referenceNumber },
                    {
                        field: 'Type',
                        value: getFullCodelistValue(list.type, ukNctsCodelists?.additionalReference),
                    },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationInput
                            label="Reference Number"
                            {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                            hidden={isFormType(['D4'])}
                            condensed
                        />
                        <DeclarationSelect
                            label="Type"
                            {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                            required
                            codeValidation={[validators.exact(4)]}
                            condensed
                            selectOptions={ukNctsCodelists?.additionalReference}
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="Additional Information"
                path="consignment.additionalInformation"
                initialValues={{ code: '', text: '' }}
                list={(list) => [
                    {
                        field: 'Code',
                        value: getFullCodelistValue(list.code, ukNctsCodelists?.additionalInformation),
                    },
                    { field: 'Text', value: list.text },
                ]}
                condensed
                hidden={isFormType(['D4'])}
            >
                {(path) => (
                    <FormCardContainer>
                        <DeclarationSelect
                            label="Code"
                            {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                            required
                            codeValidation={[validators.exact(5)]}
                            condensed
                            selectOptions={ukNctsCodelists?.additionalInformation}
                            hidden={isFormType(['D4'])}
                        />
                        <DeclarationInput
                            label="Text"
                            {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                            condensed
                            hidden={isFormType(['D4'])}
                        />
                    </FormCardContainer>
                )}
            </MultipleItemsCard>

            <MultipleItemsCard
                title="House Consignment"
                path="consignment.houseConsignment"
                beforeDelete={handleHouseConsignmentBeforeDelete}
                initialValues={{
                    countryOfDispatch: '',
                    grossMass: '',
                    referenceNumberUCR: '',
                    additionalSupplyChainActor: [],
                    departureTransportMeans: [],
                    previousDocument: [],
                    supportingDocument: [],
                    transportDocument: [],
                    additionalReference: [],
                    additionalInformation: [],
                }}
                list={(list) => [
                    {
                        field: 'Country of dispatch',
                        value: list.countryOfDispatch,
                    },
                    {
                        field: 'Reference number UCR',
                        value: list.referenceNumberUCR,
                    },
                    { field: 'Gross mass', value: list.grossMass },
                    {
                        field: 'Additional Supply Chain Actor',
                        value: list.additionalSupplyChainActor?.length.toString() ?? '0',
                    },
                    {
                        field: 'Departure Transport Means',
                        value: list.departureTransportMeans?.length.toString() ?? '0',
                    },
                    { field: 'Previous Document', value: list.previousDocument?.length.toString() ?? '0' },
                    { field: 'Supporting Document', value: list.supportingDocument?.length.toString() ?? '0' },
                    { field: 'Transport Document', value: list.transportDocument?.length.toString() ?? '0' },
                    { field: 'Additional Reference', value: list.additionalReference?.length.toString() ?? '0' },
                    { field: 'Additional Information', value: list.additionalInformation?.length.toString() ?? '0' },
                ]}
                condensed
                required
            >
                {(path) => (
                    <>
                        <FormCardContainer>
                            <CountrySelect
                                label="Country of dispatch"
                                {...getFormikProps(addPathPrefix(path, 'countryOfDispatch'), formik)}
                                condensed
                                hidden={isFormType(['D2', 'D3', 'D4'])}
                            />
                            <DeclarationNumberInput
                                required
                                label="Gross mass"
                                {...getFormikProps(addPathPrefix(path, 'grossMass'), formik)}
                                condensed
                            />
                            <DeclarationInput
                                label="Reference number UCR"
                                {...getFormikProps(addPathPrefix(path, 'referenceNumberUCR'), formik)}
                                condensed
                                hidden={isFormType(['D4'])}
                            />
                            <DeclarationSelect
                                required
                                label="Transport Charges - Method of payment"
                                {...getFormikProps(addPathPrefix(path, 'transportCharges.methodOfPayment'), formik)}
                                condensed
                                selectOptions={ukNctsCodelists?.transportChargesMethodOfPayment}
                                codeValidation={[validators.exact(1)]}
                            />
                        </FormCardContainer>
                        <PartiesCard
                            getFieldProps={formik.getFieldProps}
                            getFieldMeta={formik.getFieldMeta}
                            getFieldHelpers={formik.getFieldHelpers}
                            parties={[
                                {
                                    path: addPathPrefix(path, 'consignor'),
                                    header: 'Consignor',
                                    refNumber: undefined,
                                    contactPerson: {
                                        present: true,
                                    },
                                    hidden: isFormType(['D2', 'D3', 'D4']),
                                },
                                {
                                    path: addPathPrefix(path, 'consignee'),
                                    header: 'Consignee',
                                    refNumber: undefined,
                                    hidden: isFormType(['D4']),
                                },
                            ]}
                            paths={{
                                address: {
                                    city: 'address.city',
                                    country: 'address.country',
                                    name: 'name',
                                    postCode: 'address.postcode',
                                    streetAndNumber: 'address.streetAndNumber',
                                },
                                eori: 'identificationNumber',
                                contactPerson: {
                                    name: 'contactPerson.name',
                                    phoneNumber: 'contactPerson.phoneNumber',
                                    email: 'contactPerson.emailAddress',
                                },
                                additional: {
                                    representativeStatus: 'status',
                                },
                            }}
                            condensed
                        />
                        <MultipleItemsCard
                            title="Additional Supply Chain Actor"
                            path={addPathPrefix(path, 'additionalSupplyChainActor')}
                            initialValues={{ role: '', identificationNumber: '' }}
                            list={(list) => [
                                {
                                    field: 'Role',
                                    value: getFullCodelistValue(
                                        list.role,
                                        ukNctsCodelists?.additionalSupplyChainActorRoleCode
                                    ),
                                },
                                {
                                    field: 'Identification number',
                                    value: list.identificationNumber,
                                },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationSelect
                                        label="Role"
                                        {...getFormikProps(addPathPrefix(path, 'role'), formik)}
                                        required
                                        condensed
                                        selectOptions={ukNctsCodelists?.additionalSupplyChainActorRoleCode}
                                        codeValidation={[validators.maxLength(3)]}
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationInput
                                        required
                                        label="Identification number"
                                        {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                        <MultipleItemsCard
                            title="Departure Transport Means"
                            path={addPathPrefix(path, 'departureTransportMeans')}
                            initialValues={{ typeOfIdentification: '', identificationNumber: '', nationality: '' }}
                            list={(list) => [
                                {
                                    field: 'Type of Identification',
                                    value: getFullCodelistValue(
                                        list.typeOfIdentification,
                                        ukNctsCodelists?.typeOfIdentificationOfMeansOfTransport
                                    ),
                                },
                                { field: 'Identification Number', value: list.identificationNumber },
                                {
                                    field: 'Nationality',
                                    value: getFullCodelistValue(list.nationality, ukNctsCodelists?.nationality),
                                },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationSelect
                                        required
                                        label="Type of Identification"
                                        {...getFormikProps(addPathPrefix(path, 'typeOfIdentification'), formik)}
                                        codeValidation={[validators.number(), validators.exact(2)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.typeOfIdentificationOfMeansOfTransport}
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationInput
                                        required
                                        label="Identification Number"
                                        {...getFormikProps(addPathPrefix(path, 'identificationNumber'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationSelect
                                        required
                                        label="Nationality"
                                        {...getFormikProps(addPathPrefix(path, 'nationality'), formik)}
                                        codeValidation={[validators.exact(2)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.nationality}
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                        <MultipleItemsCard
                            title="Previous Document"
                            path={addPathPrefix(path, 'previousDocument')}
                            initialValues={{ referenceNumber: '', type: '', complementOfInformation: '' }}
                            list={(list) => [
                                { field: 'Reference Number', value: list.referenceNumber },
                                {
                                    field: 'Type',
                                    value: getFullCodelistValue(list.type, ukNctsCodelists?.previousDocumentExportType),
                                },
                                { field: 'Complement of information', value: list.complementOfInformation },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationInput
                                        label="Reference Number"
                                        {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                                        required
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationSelect
                                        label="Type"
                                        {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                                        required
                                        codeValidation={[validators.exact(4)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.previousDocumentExportType}
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationInput
                                        label="Complement of information"
                                        {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                        <MultipleItemsCard
                            title="Supporting Document"
                            path={addPathPrefix(path, 'supportingDocument')}
                            initialValues={{
                                referenceNumber: '',
                                type: '',
                                documentLineItemNumber: '',
                                complementOfInformation: '',
                            }}
                            list={(list) => [
                                { field: 'Reference Number', value: list.referenceNumber },
                                {
                                    field: 'Type',
                                    value: getFullCodelistValue(list.type, ukNctsCodelists?.supportingDocumentType),
                                },
                                { field: 'Complement of information', value: list.complementOfInformation },
                                { field: 'Document Line Item Number', value: list.documentLineItemNumber },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationInput
                                        label="Reference Number"
                                        {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                                        required
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationSelect
                                        label="Type"
                                        {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                                        required
                                        codeValidation={[validators.exact(4)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.supportingDocumentType}
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationInput
                                        label="Complement of information"
                                        {...getFormikProps(addPathPrefix(path, 'complementOfInformation'), formik)}
                                        condensed
                                        hidden={isFormType(['D2', 'D3', 'D4'])}
                                    />
                                    <DeclarationNumberInput
                                        label="Document Line Item Number"
                                        {...getFormikProps(addPathPrefix(path, 'documentLineItemNumber'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                        <MultipleItemsCard
                            title="Transport Document"
                            path={addPathPrefix(path, 'transportDocument')}
                            initialValues={{ referenceNumber: '', type: '' }}
                            list={(list) => [
                                { field: 'Reference Number', value: list.referenceNumber },
                                {
                                    field: 'Type',
                                    value: getFullCodelistValue(list.type, ukNctsCodelists?.transportDocumentType),
                                },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationInput
                                        required
                                        label="Reference Number"
                                        {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationSelect
                                        required
                                        label="Type"
                                        {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                                        codeValidation={[validators.exact(4)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.transportDocumentType}
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>

                        <MultipleItemsCard
                            title="Additional Reference"
                            path={addPathPrefix(path, 'additionalReference')}
                            initialValues={{ referenceNumber: '', type: '' }}
                            list={(list) => [
                                { field: 'Reference Number', value: list.referenceNumber },
                                {
                                    field: 'Type',
                                    value: getFullCodelistValue(list.type, ukNctsCodelists?.additionalReference),
                                },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationInput
                                        label="Reference Number"
                                        {...getFormikProps(addPathPrefix(path, 'referenceNumber'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationSelect
                                        label="Type"
                                        {...getFormikProps(addPathPrefix(path, 'type'), formik)}
                                        required
                                        codeValidation={[validators.exact(4)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.additionalReference}
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>

                        <MultipleItemsCard
                            title="Additional Information"
                            path={addPathPrefix(path, 'additionalInformation')}
                            initialValues={{ code: '', text: '' }}
                            list={(list) => [
                                {
                                    field: 'Code',
                                    value: getFullCodelistValue(list.code, ukNctsCodelists?.additionalInformation),
                                },
                                { field: 'Text', value: list.text },
                            ]}
                            condensed
                            hidden={isFormType(['D4'])}
                        >
                            {(path) => (
                                <FormCardContainer>
                                    <DeclarationSelect
                                        label="Code"
                                        {...getFormikProps(addPathPrefix(path, 'code'), formik)}
                                        required
                                        codeValidation={[validators.exact(5)]}
                                        condensed
                                        selectOptions={ukNctsCodelists?.additionalInformation}
                                        hidden={isFormType(['D4'])}
                                    />
                                    <DeclarationInput
                                        label="Text"
                                        {...getFormikProps(addPathPrefix(path, 'text'), formik)}
                                        condensed
                                        hidden={isFormType(['D4'])}
                                    />
                                </FormCardContainer>
                            )}
                        </MultipleItemsCard>
                    </>
                )}
            </MultipleItemsCard>

            <NewFormCard title="Test purposes">
                <FormCardContainer>
                    <DeclarationSelect
                        label="Phase Id"
                        {...getFormikProps('phaseID', formik)}
                        codeValidation={[validators.maxLength(12)]}
                        condensed
                        selectOptions={[
                            { id: 'NCTS_5_1', value: 'Phase NCTS 5.1' },
                            { id: 'NCTS_5_0', value: 'Phase NCTS 5.0' },
                        ]}
                    />
                </FormCardContainer>
            </NewFormCard>
            {modalContextHolder}
        </FormikProvider>
    );
};

export default UkNctsForm;
