import useCodelists from 'hooks/useCodelists';
import { FC, useCallback } from 'react';
import { CommodityCell, CommodityRow, NumberCell, TaxesTable, TaxRow } from './CommodityCodeTable.styles';

interface Props {
    data?: any;
}
const findB00 = (taxes?: { boxTaxType: string; boxTaxRate: number }[]) =>
    (taxes?.find((tax) => tax.boxTaxType === 'B00' && tax.boxTaxRate !== undefined) as {
        boxTaxType: string;
        boxTaxRate: number;
    }) || undefined;

const findA00 = (taxes?: { boxTaxType: string; boxTaxRate: number }[]) =>
    (taxes?.find((tax) => tax.boxTaxType === 'A00' && tax.boxTaxRate !== undefined) as {
        boxTaxType: string;
        boxTaxRate: number;
    }) || undefined;

const CommodityCodeTable: FC<Props> = ({ data }) => {
    const { aisCodelists } = useCodelists();

    const taxTypeDescription = useCallback(
        (taxType: string) => aisCodelists?.taxType?.find((e) => e.id === taxType)?.value,
        [aisCodelists]
    );

    return (
        <>
            <TaxesTable width={700}>
                <thead>
                    <th>Tax type</th>
                    <th style={{ textAlign: 'right' }}>Tax base</th>
                    <th style={{ textAlign: 'right' }}>Duty rate %</th>
                    <th style={{ textAlign: 'right' }}>Assessed Amount</th>
                    <th style={{ textAlign: 'right' }}>Total liability</th>
                </thead>
                <tbody>
                    {data?.list.map((product: any, index: number) => {
                        const taxes = product.taxBox43Bis;
                        const B00Found = findB00(taxes);
                        const A00Found = findA00(taxes);
                        return (
                            <>
                                {(A00Found || B00Found) && (
                                    <CommodityRow key={product.id}>
                                        <CommodityCell colSpan={5}>
                                            <strong>Commodity code:</strong>{' '}
                                            {product.goodsInformation?.combinedNomenclatureCode}
                                        </CommodityCell>
                                    </CommodityRow>
                                )}
                                {A00Found && (
                                    <A00 product={product} taxTypeDescription={taxTypeDescription} index={index} />
                                )}
                                {B00Found && (
                                    <B00 product={product} taxTypeDescription={taxTypeDescription} index={index} />
                                )}
                                {
                                    /**
                                     *  FIXME hack to add empty row to separate products
                                     */
                                    index < data.list.length - 1 ? <tr style={{ height: 16 }}></tr> : <></>
                                }
                            </>
                        );
                    })}
                </tbody>
            </TaxesTable>
        </>
    );
};
interface TaxProps {
    product: any;
    taxTypeDescription: (taxType: string) => string | undefined;
    index: number;
}

const A00: FC<TaxProps> = ({ product, taxTypeDescription, index }) => {
    const taxes = product.taxBox43Bis;
    const A00 = findA00(taxes);
    if (!A00) return null;

    const taxBase = product.statisticalValue;
    const dutyRate = A00.boxTaxRate;
    const assessedAmount = taxBase * (dutyRate / 100);
    const totalLiability = assessedAmount;

    return (
        <TaxRow key={`${product.id}-${index}`} last={index === product.taxBox43Bis.length - 1}>
            <td>
                {A00.boxTaxType} - {taxTypeDescription(A00.boxTaxType)}
            </td>
            <NumberCell>{taxBase.toFixed(2)}</NumberCell>
            <NumberCell>{dutyRate.toFixed(2)}</NumberCell>
            <NumberCell>{assessedAmount.toFixed(2)}</NumberCell>
            <NumberCell>{totalLiability.toFixed(2)}</NumberCell>
        </TaxRow>
    );
};

const B00: FC<TaxProps> = ({ product, taxTypeDescription, index }) => {
    const taxes = product.taxBox43Bis;
    const B00 = findB00(taxes);
    if (!B00) return null;

    const A00 = findA00(taxes);
    const A00taxBase = product.statisticalValue;
    const A00assessedAmount = A00 ? A00taxBase * (A00.boxTaxRate / 100) : 0;
    const B00taxBase = A00taxBase + A00assessedAmount;
    const B00dutyRate = B00.boxTaxRate;
    const B00assessedAmount = B00taxBase * (B00dutyRate / 100);
    const B00totalLiability = B00assessedAmount;

    return (
        <TaxRow key={`${product.id}-${index}`} last={index === product.taxBox43Bis.length - 1}>
            <td>
                {B00.boxTaxType} - {taxTypeDescription(B00.boxTaxType)}
            </td>
            <NumberCell>{B00taxBase.toFixed(2)}</NumberCell>
            <NumberCell>{B00dutyRate.toFixed(2)}</NumberCell>
            <NumberCell>{B00assessedAmount.toFixed(2)}</NumberCell>
            <NumberCell>{B00totalLiability.toFixed(2)}</NumberCell>
        </TaxRow>
    );
};

export default CommodityCodeTable;
